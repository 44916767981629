<div class="container">
    <div class="row row-mobile">
        <div class="col" style="text-align: end;">
            <button type="button" class="close-mobile" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-10 col-md-11 col-lg-11 col-xl-11">
            <p class="title">{{'ABOUT THE APPLICATION' | translate}}</p>
        </div>
        <div class="col-sm-2 col-md-1 col-lg-1 col-xl-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    
    <div class="line">
        <hr class="long">
    </div>

    <div class="row">
        <div class="col-sm-1 col-md-4 col-lg-4 col-xl-4">
            <img class=image alt="logo" src="assets/images/about_the_app1.png">
        </div>
        <div class="col-sm-11 col-md-8 col-lg-8 col-xl-8">
            <p class="title2">{{about_app.title}}</p>
            <div class="paragraph" [innerHTML]="formatText(about_app.info)"></div>
        </div>
    </div>
    
</div>