import { Component, OnInit } from '@angular/core';
/* import { QuestionComponent } from './question/question.component'; */
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-parent-questionnaire-one',
  templateUrl: './parent-questionnaire-one.component.html',
  styleUrls: ['./parent-questionnaire-one.component.css']
})
export class ParentQuestionnaireOneComponent implements OnInit {

  /* questionnaireId: number;
  questionnaire: Questionnaire;
  currentQuestionIndex = 0;
  answers: any = {}; */

 /*  questionnaireOneData: any;
  currentQuestionIndex = 0; */

  constructor(
    private router: Router,
    private userService: UserService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {

    //get all the questionnaires call
    /* this.userService.getQuestionnaires().pipe().subscribe({
      next: data => {
        console.log(data);

        this.questionnaireOneData = data["_items"][0];
        console.log(this.questionnaireOneData);
      },
      error: error => {
        console.log("Handle Error: ", error);
        // Handle Error Here
      }
    }); */

    //get a specific questionnaire from all the questionnaires
    /* this.router.paramMap.subscribe(params => {
      this.questionnaireId = +params.get('id');
      this.userService.getQuestionnaires(this.questionnaireId).subscribe(questionnaire => {
        this.questionnaire = questionnaire;
      });
    }); */


  }

  /* nextQuestion() {
    if (this.currentQuestionIndex < this.questionnaireOneData.length - 1) {
      this.currentQuestionIndex++;
    }
  } */

}
