import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit, AfterViewInit {
  @ViewChild('pdfViewer') pdfViewer!: ElementRef;

  language: string = "";

  constructor(private userService: UserService,
    public translate: TranslateService) { }

  ngOnInit() {
    /* console.log("LANGUAGE: ", this.translate.currentLang); */

    if (this.translate.currentLang === 'el') {
      this.language = 'GR'
    }
    else if (this.translate.currentLang === 'en') {
      this.language = 'EN'
    }
    else if (this.translate.currentLang === 'fr') {
      this.language = 'FR'
    }
    else if (this.translate.currentLang === 'ro') {
      this.language = 'RO'
    }
    else {
      this.language = 'NO'
    }
  }

  ngAfterViewInit(): void {
    this.loadPdf();
  }

  loadPdf(): void {
    this.userService.getPrivacyPolicy(this.language).subscribe({
      next: response => {
        if (response.body !== null) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(blob);
          const pdfView = this.pdfViewer.nativeElement;
  
          pdfView.src = fileURL;
        } else {
          /* console.log("Response body is null."); */
        }
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
      }
    });
  }
  
}