import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AboutApp2Component } from '../about-app2/about-app2.component';
import { ValidateEmailService } from 'src/app/services/validate-email.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  user: any = {
    username: '',
    password: '',
    auth_code: ''
  };

  modalRef: BsModalRef | undefined = undefined;

  public NavLink1: boolean = false;
  public NavLink2: boolean = false;
  public NavLink3: boolean = false;
  public NavLink4: boolean = false;
  public NavLink5: boolean = false;
  public NavLink6: boolean = false;
  public NavLink7: boolean = false;
  public NavLink8: boolean = false;
  public NavLink9: boolean = false;
  public NavLink10: boolean = false;

  constructor(public authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,
    private ValidateEmailService: ValidateEmailService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    /* console.log("current LANGUAGE: ", this.translate.currentLang); */

    if (this.translate.currentLang === 'el') {
      this.NavLink1 = true;
      this.NavLink2 = false;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'en') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = true;
      this.NavLink4 = false;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'fr') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = true;
      this.NavLink6 = false;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'ro') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = true;
      this.NavLink8 = false;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = true;
      this.NavLink10 = false;
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);

    if (language === 'el') {
      this.NavLink1 = true;
      this.NavLink2 = false;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'el'
    }
    else if (language === 'en') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = true;
      this.NavLink4 = false;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'en'
    }
    else if (language === 'fr') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = true;
      this.NavLink6 = false;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'fr'
    }
    else if (language === 'ro') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = true;
      this.NavLink8 = false;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'ro'
    }
    else {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = true;
      this.NavLink10 = false;
      this.translate.currentLang = 'no'
    }
    /* console.log("LANGUAGE: ", language);
    console.log("current LANGUAGE: ", this.translate.currentLang); */
  }

  openModal() {
    this.modalRef = this.modalService.show(AboutApp2Component);
  }

  onSignupSubmit(userForm: NgForm) {
    // Request body
    const signup_res = {
      email: this.user.username,
      password: this.user.password,
      auth_code: this.user.auth_code
    };

    if (!userForm.form.valid || !this.ValidateEmailService.validateEmail(this.user.username)) {
      this.translate.get('Please enter a valid email to sign up').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }

    else {
      // Signup Request
      this.authService.registerUser(signup_res)
        .pipe()
        .subscribe({
          next: data => {
            /* console.log(this.user);
            console.log(data); */
            this.translate.get('Your registration is complete').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });
            /* this.router.navigate(['/login']); */

            // Request body login
            const login_res = {
              username: this.user.username,
              password: this.user.password
            };
            /* login after successful register */
            this.authService.authenticateUser(login_res)
              .pipe(first())
              .subscribe({
                next: data => {
                  /* console.log("USER:", this.user);
                  console.log(data); */
                  this.translate.get('You are now logged in!').subscribe(res => {
                    /* console.log(res); */
                    this.toastr.success(res);
                  });
                  this.authService.storeUserData(data.token, data.user);

                  // Get User Profile
                  this.userService.getProfile()
                    .pipe()
                    .subscribe({
                      next: res => {
                        /* console.log("User profile: ", res); */
                        this.dataLayerService.addUser(res);
                        this.router.navigate(['/about-app']);
                      },
                      error: error => {
                        if (error.status === 401) {
                          this.translate.get('Unauthorized').subscribe(res => {
                            /* console.log(res); */
                            this.toastr.error(res);
                          });
                        }
                      }
                    });
                },
                error: error => {
                  if (error.status == 401) {
                    this.translate.get('Invalid email address or password').subscribe(res => {
                      /* console.log(res); */
                      this.toastr.error(res);
                    });
                  }
                  else {
                    this.translate.get('Something went wrong. Please try again.').subscribe(res => {
                      /* console.log(res); */
                      this.toastr.error(res);
                    });
                  }
                }
              });
          },
          error: error => {
            /* console.log(error); */
            if (error.status === 401) {
              this.translate.get('Invalid invitation code or email address or password').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
            else if (error.error._error.code === 422) {
              /* console.log(error.error._error.message); */
              if (error.error._error.message === 'Patient id already exists') {
                this.translate.get('Patient id already exists').subscribe(res => {
                  /* console.log(res); */
                  this.toastr.error(res);
                });
              }
              if (error.error._error.message === 'Email account already exists') {
                this.translate.get('This email address is not unique').subscribe(res => {
                  /* console.log(res); */
                  this.toastr.error(res);
                });

              }
            }
            else {
              this.translate.get('Something went wrong. Please try again.').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
          }
        });
    }
  }
}