import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about-app2',
  templateUrl: './about-app2.component.html',
  styleUrls: ['./about-app2.component.css']
})
export class AboutApp2Component implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();

  public about_app: any = [];

  language: string = "";

  constructor(public modalRef: BsModalRef,
    private userService: UserService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    /* console.log("LANGUAGE: ", this.translate.currentLang); */

    if (this.translate.currentLang === 'el') {
      this.language = 'GR'
    }
    else if (this.translate.currentLang === 'en') {
      this.language = 'EN'
    }
    else if (this.translate.currentLang === 'fr') {
      this.language = 'FR'
    }
    else if (this.translate.currentLang === 'ro') {
      this.language = 'RO'
    }
    else {
      this.language = 'NO'
    }

    this.userService.getAboutApp(this.language).pipe().subscribe({
      next: data => {
        this.about_app = data;
        /* console.log("about_app: ", this.about_app); */
        /* console.log("about_app: ", this.about_app.info); */
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });
  }

  formatText(text: string): string {
    // Ελέγξτε αν η τιμή είναι ορισμένη πριν από την επεξεργασία
    if (text) {
      // Επεξεργασία του κειμένου για να προσθέσετε HTML μορφοποίηση
      const formattedText = text.split('\n').map(paragraph => `<p>${paragraph}</p>`).join('<br>');
      return formattedText;
    } else {
      return ''; // ή μια άλλη προεπιλεγμένη τιμή αν η ιδιότητα είναι undefined
    }
  }

  closeModal() {
    this.modalRef?.hide();
  }

}