<div class="container">

    <div class="top"style="text-align: center;justify-content: center;">
      <a [routerLink]="['/login']">
        <img alt="logo" src="assets/images/evivios_med.jpg" class="login-logo">
      </a>
    </div>

    <div class="mb-3" style="display: flex;justify-content: center;">
        <p class="paragraph">{{'Please enter your new password.' | translate}}
    </div>
            
    <form #userForm="ngForm" (NgSubmit)="userForm.valid && onSubmit(userForm)">
      
      <div class="row" style="display: flex;justify-content: center;margin-top: 10px;">
        <div class="col" style="display: flex;justify-content: end;">
            <a *ngIf="NavLink1" class="nav-link el-active" value="el" (click)="useLanguage('el')" style="border: none; cursor: pointer; background: #fff;margin-top: -1px"> 
              <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}</a>
            <a *ngIf="NavLink2" class="nav-link el" value="el" (click)="useLanguage('el')" style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
              <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}</a>
        </div>
        <div class="col">
          <a *ngIf="NavLink3" class="nav-link en-active" value="en" (click)="useLanguage('en')"  style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
            <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
          </a>
          <a *ngIf="NavLink4" class="nav-link en" value="en" (click)="useLanguage('en')"  style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
            <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
          </a>
        </div>
      </div>
  
        <div class="row" style="display: flex;justify-content: center;">
          <div class="col" style="display: flex;justify-content: center;">
            <div class="mb-3 email-field">
              <label for="exampleInputEmail1" class="form-label">{{'Email' | translate}}</label>
              <input type="text" class="form-control email-input" id="exampleInputEmail1" aria-describedby="emailHelp" name="username" [(ngModel)]="user.username" #username="ngModel" email required style="background-image: url('assets/images/email-icon.png');">
              <small class="smallclass" *ngIf="username.touched && !username.valid">{{'Enter your email address!' | translate}}</small>
              <br>
              <small class="smallclass" *ngIf="username.touched && !username.valid">{{'Must be a valid email address.' | translate}}</small>
            </div>
          </div>
        </div>

        <div class="row" style="display: flex;justify-content: center;">
            <div class="col" style="display: flex;justify-content: center;">
              <div class="mb-3 verification-field">
                <label for="exampleInputVerification1" class="form-label">{{'Verification code (via email)' | translate}}</label>
                <input type="text" onkeypress="return /[0-9]/i.test(event.key)" class="form-control verification-input" id="exampleInputVerification1" name="auth_code" [(ngModel)]="user.auth_code" #auth_code="ngModel" required minlength="6" maxlength="6" style="background-image: url('assets/images/locker-icon.png');">
                <small class="smallclass" *ngIf="auth_code.touched && !auth_code.valid">{{'Enter your verification code!' | translate}}</small>
              </div>
            </div>
        </div> 

        <div class="row" style="display: flex;justify-content: center;">
            <div class="col" style="display: flex;justify-content: center;">
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">{{'New password' | translate}}</label>
                <input type="password" class="form-control pass-input" id="exampleInputPassword1" name="password" [(ngModel)]="user.password" #password="ngModel" minlength="6" required style="background-image: url('assets/images/key-icon.png');">
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Enter your password!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Password should be at least 6 characters long.' | translate}}</small>
              </div>
            </div>
        </div> 
    
        <div class="row" style="display: flex;justify-content: center;">
          <div class="col" style="display: flex;justify-content: center;">
            <div class="mb-3">
              <button type="submit" class="btn-black" [disabled]="!userForm.form.valid" (click)="onSubmit(userForm)">{{'NEXT' | translate}}</button>
            </div>
          </div>
        </div>
    </form>
    
  </div>
