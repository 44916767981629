import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  user: any = {
    username: ''
  };

  public NavLink1: boolean = false;
  public NavLink2: boolean = false;
  public NavLink3: boolean = false;
  public NavLink4: boolean = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.NavLink1 = true;
    this.NavLink2 = false;
    this.NavLink3 = false;
    this.NavLink4 = true;
  }

  useLanguage(language: string) {
    this.translate.use(language);

    if (language === 'el') {
      this.NavLink1 = true;
      this.NavLink2 = false;
      this.NavLink3 = false;
      this.NavLink4 = true;
    }
    else {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = true;
      this.NavLink4 = false;
    }
  }

  onSubmit(userForm: NgForm) {
    // Request body
    const forgotPass_res = {
      email: this.user.username
    };

    if (!userForm.form.valid) {
      this.translate.get('Please enter your email to continue').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }

    else {
      // Forgot Password1 Request
      this.authService.forgotPassword(forgotPass_res)
        .pipe()
        .subscribe({
          next: data => {
            this.translate.get('Your verification code has been sent to your email').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });
            this.router.navigate(['/forgot-pass2']);
          },
          error: error => {
            if (error.status === 401) {
              this.translate.get('Invalid email address').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
            else if (error.status === 422) {
              this.translate.get('This email address is not unique').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
            else {
              this.translate.get('Something went wrong. Please try again.').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
          }
        });
    }
  }
}