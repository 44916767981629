import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.css']
})
export class WorksComponent implements OnInit {
  foundCompleted = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    // ανάκτηση τιμών από το localStorage
    const allDataQuestionnaires = JSON.parse(localStorage.getItem('all_questionnaires') || '{}');
    /* console.log(allDataQuestionnaires); */

    for (let i = 0; i < allDataQuestionnaires.length; i++) {
      if (!allDataQuestionnaires[i].is_completed) {
        this.foundCompleted = true;
        break;
      }
    }
    /* console.log(this.foundCompleted); */
  }
}