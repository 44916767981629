<app-navbar></app-navbar>
<div class="container">
  <form [formGroup]="StepThirteenForm" (ngSubmit)="onAnswerSubmitted()">
    <div class="row" style="margin-top: 50px;">
      <div class="col"> 
        <h2 class="title">{{ 'Questionnaire' | translate }}</h2>
      </div>
      <div class="col" style="text-align: end;">
        <div *ngIf="timeLeft">
          <div class="timer">{{ timeLeft }}</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="steps-info">
      <div class="row">
        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
          <p class="step-num">{{question_length}}/{{all_questions_length}}</p>
        </div>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10" style="padding-top: 20px;">
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width:65%;position: relative;"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" style="text-align: center;">
        <!-- Greek language -->
        <p class="que-title" *ngIf="translate.currentLang === 'el'">{{ question13_el }}</p>
        <!-- English language -->
        <p class="que-title" *ngIf="translate.currentLang === 'en'">{{ question13_en }}</p>
      </div>
    </div>
    <!-- Greek language -->
    <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-top: 20px;" *ngIf="translate.currentLang === 'el'">
      <div *ngFor="let option of options_el" class="options-format">
        <input formControlName="selectedOption" class="form-check-input" type="radio" id="{{ option }}" name="selectedOption" value="{{ option }}" required (click)="optionSelected = true" />
        <label class="form-check-label" for="{{ option }}">{{ option }}</label>
      </div>
    </div>
    <!-- English language -->
    <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-top: 20px;" *ngIf="translate.currentLang === 'en'">
      <div *ngFor="let option of options_en" class="options-format">
        <input formControlName="selectedOption" class="form-check-input" type="radio" id="{{ option }}" name="selectedOption" value="{{ option }}" required (click)="optionSelected = true" />
        <label class="form-check-label" for="{{ option }}">{{ option }}</label>
      </div>
    </div>
    <!-- </div> -->
    <div class="row">
      <div class="col" style="text-align: center;">
        <button type="submit" class="btn-black" [disabled]="!optionSelected">{{ 'Continue' | translate }}</button>
      </div>
    </div>
  </form>
</div>