import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { UserService } from '../services/user.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DataLayerService } from '../services/data-layer.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(300)
      ]),
      transition('* => void', [
        animate(300, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class QuestionComponent implements OnInit {
  public questionnaireOneData: any = [];
  public selected_questionnaire_items: any = [];

  public total_score = 0;
  public total_score_percentage = 0;
  user_id: any = "";
  questionnaire_id: any = "";
  _id: any = "";
  questionnaire_name: string = "";
  language: any = '';

  public questionIndex = 0;

  optionSelected = false;

  public answersForm: FormGroup;
  public selectedOption = '';

  answers_array: any[] = [];

  question_id_forOneQuestion: any;

  QandA_array: any[] = [];

  // timeLeft
  targetDate!: Date;
  timeLeft: any;
  public displayTime = '01:00';
  private timer!: any;

  progressPercentage = 0;

  timesUp = 0;

  isTimerRunning = false;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    public dataLayerService: DataLayerService,
    private fb: FormBuilder) {
    this.answersForm = this.fb.group({
      selectedOption: [this.selectedOption, Validators.compose([Validators.required])]
    });
  }

  ngOnInit(): void {
    /* console.log("LANGUAGE: ", this.translate.currentLang); */

    if (this.translate.currentLang === 'el') {
      this.language = 'GR'
    }
    else if (this.translate.currentLang === 'en') {
      this.language = 'EN'
    }
    else if (this.translate.currentLang === 'fr') {
      this.language = 'FR'
    }
    else if (this.translate.currentLang === 'ro') {
      this.language = 'RO'
    }
    else if (this.translate.currentLang === 'no') {
      this.language = 'NO'
    }

    // βαζουμε το questionnaire _id διπλα στο router της σελιδας parent-questionnaire-one/_id
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const questionnaireID = params.get("id");
      this._id = questionnaireID;
      /* console.log("QUESTIONNAIRE _ID:", this._id); */

      // ανάκτηση τιμών από το localStorage για το user_id του χρήστη
      const myUserID = JSON.parse(localStorage.getItem('USER_ID') || '{}');
      this.user_id = myUserID;
      /* console.log("USER_ID:", this.user_id); */

      // ανάκτηση τιμών από το localStorage αντί για κλήση get questionnaires
      const allDataQuestionnaires = JSON.parse(localStorage.getItem('all_questionnaires') || '{}');
      /*  console.log(allDataQuestionnaires); */

      // Filtering με βάση το questionnaire _id που είναι δίπλα στο router της σελίδας
      this.selected_questionnaire_items = allDataQuestionnaires.filter((obj: any) => {
        return obj._id === this._id;
      });
      /* console.log("SELECTED QUESTIONNAIRE:", this.selected_questionnaire_items); */
      // Aύτο θα σου εμφανίσει το ερωτηματολόγιο που επέλεξε ο χρήστης στο home
      this.questionnaire_name = this.selected_questionnaire_items[0].name;
      this.questionnaire_id = this.selected_questionnaire_items[0].questionnaire_id;
      /* console.log("QUESTIONNAIRE NAME:", this.questionnaire_name);
      console.log("QUESTIONNAIRE ID:", this.questionnaire_id); */

      this.questionnaireOneData = this.selected_questionnaire_items[0];
      /* console.log("PUT SELECTED QUESTIONNAIRE IN questionnaireOneData VALUE", this.questionnaireOneData);
      console.log("Question answer_id indexs:", this.questionnaireOneData.items[this.questionIndex].answers);
      console.log("QUESTIONNAIRE ANSWERS:", this.questionnaireOneData.answers);
      console.log("QUESTION ID:", this.questionnaireOneData.items[this.questionIndex].question_id); */

      // timeLeft
      this.targetDate = new Date();
      // Προσθήκη 1 λεπτού
      this.targetDate.setMinutes(this.targetDate.getMinutes() + 1);
      // Προσθήκη 2 δευτερολέπτων ώστε το χρονόμετρο να ξεκινά από το 1 λεπτό ακριβώς
      /* this.targetDate.setSeconds(this.targetDate.getSeconds() + 2); */
      this.timer = setInterval(() => {
        this.timeLeft = this.calculateTimeLeft();
      }, 1000);

      //progressBarPercentage
      this.progressPercentage = ((this.questionIndex + 0) / this.questionnaireOneData.items.length) * 100;

      this.answersForm = this.fb.group({
        selectedOption: [this.selectedOption, Validators.compose([Validators.required])]
      });
    });
  }

  ngOnDestroy() {
    // σταμάτημα χρονομέτρου
    clearInterval(this.timer);
    this.questionnaireOneData = {};
    /* console.log(this.questionnaireOneData); */
  }

  calculateTimeLeft() {
    let difference = +this.targetDate - +new Date();
    let minutesLeft = Math.floor(difference / (1000 * 60));
    let secondsLeft = Math.floor((difference / 1000) % 60);

    if (difference < 0 && this.timesUp === 0) {
      // Ο χρόνος έληξε για πρώτη φορά, οπότε αυξάνουμε τη μεταβλητή timesUp κατά ένα και θέτουμε ξανά τον χρόνο στο 1 λεπτό
      this.timesUp++;
      minutesLeft = 1;
      secondsLeft = 0;
      this.targetDate = new Date();
      // Προσθήκη 1 λεπτού
      this.targetDate.setMinutes(this.targetDate.getMinutes() + 1);
      // Προσθήκη 2 δευτερολέπτων ώστε το χρονόμετρο να ξεκινά από το 1 λεπτό ακριβώς
      /* this.targetDate.setSeconds(this.targetDate.getSeconds() + 2); */
      this.translate.get('Please answer the question!').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    } else if (difference < 0 && this.timesUp === 1) {
      // Ο χρόνος έληξε για δεύτερη φορά, οπότε εκτελούμε τις ενέργειες και κάνουμε το navigate
      clearInterval(this.timer);
      this.questionnaireOneData = {};
      /* console.log(this.questionnaireOneData); */
      this.translate.get('Time is up!').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
      this.router.navigate(['/home']);
      this.dataLayerService.restoreStateOnRefresh();
    }

    this.displayTime = ('0' + minutesLeft).slice(-2) + ':' + ('0' + secondsLeft).slice(-2);
    return this.displayTime;
  }

  nextQuestion() {
    const question_answer = this.answersForm.controls['selectedOption'].value;
    if (this.answersForm.controls['selectedOption'].value) {
      // σταμάτημα χρονομέτρου
      clearInterval(this.timer);

      // Επαναφορά της επιλογής του χρήστη και εμφανισιακά και προγραμματικά
      const radioButtons = document.querySelectorAll<HTMLInputElement>('.form-check-input[type="radio"]');
      radioButtons.forEach((radioButton) => {
        radioButton.checked = false;
      });
      this.answersForm.controls['selectedOption'].setValue(null);

      //progressBarPercentage
      this.progressPercentage = ((this.questionIndex + 1) / this.questionnaireOneData.items.length) * 100;
      /* console.log('progressPercentage: ' + this.questionIndex); */

      /* console.log('QUESTION INDEX: ' + this.questionIndex);
      console.log("total score " + this.total_score);
      console.log("max total score " + this.questionnaireOneData.max_total_score);
      console.log("percetage of total score " + this.total_score_percentage); */
      this.question_id_forOneQuestion = this.questionnaireOneData.items[this.questionIndex].question_id;
      /* console.log("question_id for that specific question " + this.question_id_forOneQuestion); */
      this.cdr.detectChanges();
      // Οδηγούμε τον χρήστη στην επόμενη ερώτηση
      /* this.questionIndex++; */

      const score_answer = this.questionnaireOneData.answers[question_answer - 1].score;
      this.total_score += score_answer;
      this.total_score_percentage = (this.total_score / this.questionnaireOneData.max_total_score) * 100;

      // Προσθήκη στον πίνακα απαντήσεων
      this.answers_array.push(question_answer);
      const QandA_forOneQuestion = { question_id: this.question_id_forOneQuestion, answer_id: question_answer };
      this.QandA_array.push(QandA_forOneQuestion);

      console.log("q&a array for only one specific question ", QandA_forOneQuestion);
      console.log("array with answers only " + this.answers_array);
      //Combine q&a from each question in one array
      console.log("q&a array after push ", this.QandA_array);

      // Ελέγχος για το αν έχουν συμπληρωθεί όλες οι απαντήσεις
      if (this.answers_array.length === this.questionnaireOneData.items.length) {
        // Δημιουργία αντικειμένου για POST call
        const answerBody = {
          questionnaire_id: this.questionnaire_id,
          total_score: this.total_score,
          total_score_percentage: this.total_score_percentage,
          user_id: this.user_id,
          questions: this.QandA_array,
          language: this.language
        };

        /* console.log("answerBody:", answerBody); */

        // Save questionnaire answers (post call)
        this.userService.saveQuestionnaireAnswers(answerBody).pipe().subscribe({
          next: data => {
            clearInterval(this.timer);
            /* console.log("Successful response: ", data);
            console.log("Next questionnaire id: ", data.item_id); */
            if (data.item_id === null) {
              /* console.log("Message:", data.message) */
              /* this.router.navigate(['/congrats'], { queryParams: { message: data.message } }); */
              this.router.navigate(['/congrats']);
            }
            else {
              // get του επομενου ερωτηματολογιου και οδηγουμε τον χρηστη στις οδηγιες αυτου του ερωτηματολογιου
              this.router.navigate(['/questions-info/' + data.item_id]);
            }
          },
          error: error => {
            clearInterval(this.timer);
            this.questionnaireOneData = {};
            /* console.log("Handle Error: ", error); */
            this.translate.get('Questionnaire completion failed!').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
            this.router.navigate(['/home']);
            this.dataLayerService.restoreStateOnRefresh();
          }
        });
      } else {
        // Μετάβαση στην επόμενη ερώτηση
        this.questionIndex++;
      }

      // Κώδικας ελέγχου και εκκίνησης του χρονομέτρου για την επόμενη ερώτηση
      if (this.questionIndex < this.questionnaireOneData.items.length) {
        this.targetDate = new Date();
        this.targetDate.setMinutes(this.targetDate.getMinutes() + 1);
        this.timer = setInterval(() => {
          this.timeLeft = this.calculateTimeLeft();
        }, 1000);
      }

      // Μετακίνηση στην κορυφή της σελίδας
      window.scrollTo(0, 0);

      // Αρχικοποίηση της μεταβλητής timesUp σε 0 ώστε να γίνεται η διαδικασία της calculateTimeLeft() για κάθε ερώτηση 
      this.timesUp = 0;
    }
  }

  onAnswerSubmitted() {
    this.targetDate = new Date();
    // Προσθήκη 1 λεπτού
    this.targetDate.setMinutes(this.targetDate.getMinutes() + 1);
    // Προσθήκη 2 δευτερολέπτων ώστε το χρονόμετρο να ξεκινά από το 1 λεπτό ακριβώς
    /* this.targetDate.setSeconds(this.targetDate.getSeconds() + 2); */
    this.timer = setInterval(() => {
      this.timeLeft = this.calculateTimeLeft();
    }, 1000);

    // Επαναφορά της επιλογής του χρήστη και εμφανισιακά και προγραμματικά
    const radioButtons = document.querySelectorAll<HTMLInputElement>('.form-check-input[type="radio"]');
    radioButtons.forEach((radioButton) => {
      radioButton.checked = false;
    });
    this.answersForm.controls['selectedOption'].setValue(null);

    const question_answer = this.answersForm.controls['selectedOption'].value;
    /* console.log("answer_id " + question_answer); */

    //score
    const score_answer = this.questionnaireOneData.answers[question_answer - 1].score;
    /* console.log("score " + score_answer); */

    //total score
    this.total_score = this.total_score + score_answer;
    /* console.log("total score " + this.total_score); */

    //max total score
    /* console.log("max total score " + this.questionnaireOneData.max_total_score); */

    //percetage of total score 
    this.total_score_percentage = (this.total_score / this.questionnaireOneData.max_total_score) * 100;
    /* console.log("percetage of total score " + this.total_score_percentage); */

    // Push question_answer into answers_array
    this.answers_array.push(question_answer);
    /* console.log("array with answers only " + this.answers_array); */

    //q&a
    const QandA_forOneQuestion = { question_id: this.question_id_forOneQuestion, answer_id: question_answer };
    /* console.log("q&a array for only one specific question ", QandA_forOneQuestion);
    console.log("array with answers only " + this.answers_array); */

    //Combine q&a from each question in one array
    this.QandA_array.push(QandA_forOneQuestion);
    /* console.log("q&a array after push ", this.QandA_array); */

    //post call
    if (this.answers_array.length === this.questionnaireOneData.items.length) {

      const answerBody = {
        questionnaire_id: this.questionnaire_id,
        total_score: this.total_score,
        total_score_percentage: this.total_score_percentage,
        user_id: this.user_id,
        questions: this.QandA_array,
        language: this.language
      };
      /* console.log("answerBody:", answerBody); */

      // Save questionnaire answers (post call)
      this.userService.saveQuestionnaireAnswers(answerBody).pipe().subscribe({
        next: data => {
          clearInterval(this.timer);
          /* console.log("Successful response: ", data);
          console.log("Next questionnaire id: ", data.item_id); */
          if (data.item_id === null) {
            console.log("Message:", data.message)
            /* this.router.navigate(['/congrats'], { queryParams: { message: data.message } }); */
            this.router.navigate(['/congrats']);
          }
          else {
            // get του επομενου ερωτηματολογιου και οδηγουμε τον χρηστη στις οδηγιες αυτου του ερωτηματολογιου
            this.router.navigate(['/questions-info/' + data.item_id]);
          }
        },
        error: error => {
          clearInterval(this.timer);
          this.questionnaireOneData = {};
          /* console.log("Handle Error: ", error); */
          this.translate.get('Questionnaire completion failed!').subscribe(res => {
            /* console.log(res); */
            this.toastr.error(res);
          });
          this.router.navigate(['/home']);
          this.dataLayerService.restoreStateOnRefresh();
        }
      });
    }

  }

}