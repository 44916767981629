import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';

@Component({
  selector: 'app-congrats',
  templateUrl: './congrats.component.html',
  styleUrls: ['./congrats.component.css']
})
export class CongratsComponent implements OnInit {

  language: string = "";

  onMessage: string = "";

  constructor(private router: Router,
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    public dataLayerService: DataLayerService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    /* console.log("LANGUAGE: ", this.translate.currentLang); */

    if (this.translate.currentLang === 'el') {
      this.language = 'GR'
    }
    else if (this.translate.currentLang === 'en') {
      this.language = 'EN'
    }
    else if (this.translate.currentLang === 'fr') {
      this.language = 'FR'
    }
    else if (this.translate.currentLang === 'ro') {
      this.language = 'RO'
    }
    else {
      this.language = 'NO'
    }

    // Ανάκτηση της τιμής της παραμέτρου 'message' από το URL
    /* this.activatedRoute.queryParams.subscribe(params => {
      const congrats_message = params['message'];
      console.log("Message:", congrats_message);
      this.onMessage = congrats_message;
    }); */
  }

  onCongrats() {
    this.translate.get('Your answers have been validated!').subscribe(res => {
      /* console.log(res); */
      this.toastr.success(res);
    });
    /* this.router.navigate(['/home'], { queryParams: { message: this.onMessage } }); */
    this.router.navigate(['/about-app']);
    this.dataLayerService.restoreStateOnRefresh();
  }
}