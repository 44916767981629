<app-navbar></app-navbar>
<div class="container">

    <div class="row title-row">
        <div class="col">
            <p class="title">{{'ABOUT THE APPLICATION' | translate}}</p>
        </div>
    </div>

    <div class="line">
        <hr class="long">
    </div>

    <div class="row">
        <div class="col-sm-1 col-md-4 col-lg-4 col-xl-4">
          <img class="image" alt="logo" src="assets/images/about_the_app1.png">
        </div>
        <div class="col-sm-11 col-md-8 col-lg-8 col-xl-8">
          <p class="title2">{{about_app.title}}</p>
          <div class="paragraph" [innerHTML]="formatText(about_app.info)"></div>
        </div>
        <div class="col-sm-11 col-md-6 col-lg-7 col-xl-8" style="text-align: right;">
            <button type="submit" class="btn-black" (click)="onclick()" translate>Continue</button>
        </div>
    </div>
</div>