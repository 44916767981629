import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-questions5-info',
  templateUrl: './questions5-info.component.html',
  styleUrls: ['./questions5-info.component.css']
})
export class Questions5InfoComponent implements OnInit {
  questionnaire_name: string = "";
  /* instructions_el: string = "";
  instructions_en: string = ""; */

  instructions: string = "";

  questionnaire_id: any = "";

  public selected_questionnaire_items: any = [];

  emptyInstructions = false;

  constructor(public translate: TranslateService,
    private userService: UserService,
    private router: Router,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    /* console.log("LANGUAGE: ", this.translate.currentLang); */

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const questionnaireID = params.get("id");
      this.questionnaire_id = questionnaireID;
      /* console.log("ID:", this.questionnaire_id); */

      /*  1os tropos με get call specific questionnaire */
      /* this.userService.getSpecificQuestionnaire(this.questionnaire_id).pipe().subscribe({
        next: data => {
          console.log("specific questionnaire:", data);
          this.questionnaire_name = data.name;
          this.instructions = data.instructions;
          this.selected_questionnaire_items = data._id;

          if (this.instructions === '') {
            this.emptyInstructions = true;
          }
        },
        error: error => {
          console.log("Handle Error: ", error);
          // Handle Error Here
        }
      }); */

      /*  2os tropos */
      // Ανάκτηση τιμών από το localStorage
      const allDataQuestionnaires = JSON.parse(
        localStorage.getItem("all_questionnaires") || "{}"
      );

      // Φιλτράρισμα βάσει ID του ερωτηματολογίου
      this.selected_questionnaire_items = allDataQuestionnaires.filter(
        (obj: any) => {
          return obj._id === this.questionnaire_id;
        }
      );

      this.questionnaire_name = this.selected_questionnaire_items[0].name;
      /* console.log("SELECTED QUESTIONNAIRE NAME:", this.questionnaire_name); */
      /* this.instructions_el = this.selected_questionnaire_items[0].instructions_el;
      this.instructions_en = this.selected_questionnaire_items[0].instructions_en;
      console.log(this.instructions_el);
      console.log(this.instructions_en); */
      this.instructions = this.selected_questionnaire_items[0].instructions;
      /* console.log("SELECTED QUESTIONNAIRE INSTRUCTIONS:", this.instructions); */
      if (this.instructions === '') {
        this.emptyInstructions = true;
      }
    });
  }

  onclick() {
    /* console.log("questionnaire _id: ", this.selected_questionnaire_items[0]._id); */
    /* 1ος τροπος */
    /* this.router.navigate(['/parent-questionnaire-one/' + this.selected_questionnaire_items]); */
    /* 2ος τροπος */
    this.router.navigate(['/parent-questionnaire-one/' +  this.selected_questionnaire_items[0]._id]);
  }
}