import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-fifth-questionnaire-step1',
  templateUrl: './fifth-questionnaire-step1.component.html',
  styleUrls: ['./fifth-questionnaire-step1.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(300)
      ]),
      transition('* => void', [
        animate(300, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class FifthQuestionnaireStep1Component implements OnInit {
  targetDate!: Date;
  timeLeft: any;

  options_el = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5', 'Option 6'];

  options_en = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5', 'Option 6'];

  @Input('questionnaire_obj5') public questionnaire_obj5: any = {
    questionnaire_id: '',
    total_score: 0,
    total_score_percentage: 0,
    user_id: '',
    questions: [
      { question_id: '', answer_id: 0 }
    ]
  };
  /* @Input() timeLeft!: any; */

  public selectedOption = '';

  public displayTime = '01:00';
  private timer!: any;

  public StepOneForm: FormGroup;

  optionSelected = false;

  question1_el = '';
  question1_en = '';

  answer1_el = '';
  answer1_en = '';
  answer2_el = '';
  answer2_en = '';
  answer3_el = '';
  answer3_en = '';
  answer4_el = '';
  answer4_en = '';
  answer5_el = '';
  answer5_en = '';
  answer6_el = '';
  answer6_en = '';

  all_questions_length = 0;
  question_length = 0;

  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private userService: UserService,
    public translate: TranslateService,
    private fb: FormBuilder
  ) {
    this.StepOneForm = this.fb.group({
      selectedOption: [this.selectedOption, Validators.compose([Validators.required])]
    });
  }

  ngOnInit(): void {
    this.targetDate = new Date();
    this.targetDate.setMinutes(this.targetDate.getMinutes() + 10);
    this.timer = setInterval(() => {
      this.timeLeft = this.calculateTimeLeft();
    }, 1000);

    // ανάκτηση τιμών από το localStorage
    const myUserID = JSON.parse(localStorage.getItem('USER_ID') || '{}');
    this.questionnaire_obj5.user_id = myUserID;
    console.log(this.questionnaire_obj5.user_id);

    // ανάκτηση τιμών από το localStorage
    const allDataQuestionnaires = JSON.parse(localStorage.getItem('all_questionnaires') || '{}');

    // θέλουμε τo 5ο ερωτηματολόγιο (που έχει 1 ερώτηση) άρα:
    console.log(allDataQuestionnaires[4]);

    this.questionnaire_obj5.questionnaire_id = allDataQuestionnaires[4].questionnaire_id;

    this.questionnaire_obj5.questions[0].question_id = allDataQuestionnaires[4].items[0].question_id;
    this.question_length = allDataQuestionnaires[4].items[0].question_id;
    this.all_questions_length = allDataQuestionnaires[4].items.length;

    this.question1_el = allDataQuestionnaires[4].items[0].question_el;
    this.question1_en = allDataQuestionnaires[4].items[0].question_en;

    this.answer1_el = allDataQuestionnaires[4].answers[0].answer_el;
    this.answer1_en = allDataQuestionnaires[4].answers[0].answer_en;
    this.answer2_el = allDataQuestionnaires[4].answers[1].answer_el;
    this.answer2_en = allDataQuestionnaires[4].answers[1].answer_en;
    this.answer3_el = allDataQuestionnaires[4].answers[2].answer_el;
    this.answer3_en = allDataQuestionnaires[4].answers[2].answer_en;
    this.answer4_el = allDataQuestionnaires[4].answers[3].answer_el;
    this.answer4_en = allDataQuestionnaires[4].answers[3].answer_en;
    this.answer5_el = allDataQuestionnaires[4].answers[4].answer_el;
    this.answer5_en = allDataQuestionnaires[4].answers[4].answer_en;
    this.answer6_el = allDataQuestionnaires[4].answers[5].answer_el;
    this.answer6_en = allDataQuestionnaires[4].answers[5].answer_en;

    this.options_el[0] = this.answer1_el;
    this.options_el[1] = this.answer2_el;
    this.options_el[2] = this.answer3_el;
    this.options_el[3] = this.answer4_el;
    this.options_el[4] = this.answer5_el;
    this.options_el[5] = this.answer6_el;

    this.options_en[0] = this.answer1_en;
    this.options_en[1] = this.answer2_en;
    this.options_en[2] = this.answer3_en;
    this.options_en[3] = this.answer4_en;
    this.options_en[4] = this.answer5_en;
    this.options_en[5] = this.answer6_en;

    this.StepOneForm = this.fb.group({
      selectedOption: [this.selectedOption, Validators.compose([Validators.required])]
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  calculateTimeLeft() {
    let difference = +this.targetDate - +new Date();
    let minutesLeft = Math.floor(difference / (1000 * 60));
    let secondsLeft = Math.floor((difference / 1000) % 60);

    this.displayTime = ('0' + minutesLeft).slice(-2) + ':' + ('0' + secondsLeft).slice(-2);

    if (this.displayTime === '00:00') {
      this.translate.get('Time is up!').subscribe(res => {
        console.log(res);
        this.toastr.error(res);
      });
      clearInterval(this.timer);
      this.router.navigate(['/home']);
    }
    return this.displayTime;
  }

  getSecondsAsDigitalClock(minutes: number) {
    let sec_num = minutes * 60;
    let minutesString = Math.floor(minutes).toString();
    let secondsString = '';

    this.displayTime = minutesString + ':00';

    this.timer = setInterval(() => {
      sec_num--;
      let minutes = Math.floor(sec_num / 60);
      let seconds = sec_num - minutes * 60;

      if (seconds < 10) {
        secondsString = '0' + seconds;
      } else {
        secondsString = seconds.toString();
      }

      this.displayTime = minutesString + ':' + secondsString;

      if (sec_num === 0) {
        clearInterval(this.timer);
        /* this.handleTimerExpiry(); */
      }
    }, 1000);
  }

  onAnswerSelected(selectedOption: string) {
    this.selectedOption = selectedOption;
    this.optionSelected = true;
  }

  onAnswerSubmitted() {
    clearInterval(this.timer);
    const question_answer = this.StepOneForm.controls['selectedOption'].value;
    console.log(question_answer);
    if (question_answer === this.answer1_el || question_answer === this.answer1_en) {
      this.questionnaire_obj5.questions[0].answer_id = 1;
      this.questionnaire_obj5.total_score = this.questionnaire_obj5.total_score + 5;
    }
    else if (question_answer === this.answer2_el || question_answer === this.answer2_en) {
      this.questionnaire_obj5.questions[0].answer_id = 2;
      this.questionnaire_obj5.total_score = this.questionnaire_obj5.total_score + 4;
    }
    else if (question_answer === this.answer3_el || question_answer === this.answer3_en) {
      this.questionnaire_obj5.questions[0].answer_id = 3;
      this.questionnaire_obj5.total_score = this.questionnaire_obj5.total_score + 3;
    }
    else if (question_answer === this.answer4_el || question_answer === this.answer4_en) {
      this.questionnaire_obj5.questions[0].answer_id = 4;
      this.questionnaire_obj5.total_score = this.questionnaire_obj5.total_score + 2;
    }
    else if (question_answer === this.answer5_el || question_answer === this.answer5_en) {
      this.questionnaire_obj5.questions[0].answer_id = 5;
      this.questionnaire_obj5.total_score = this.questionnaire_obj5.total_score + 1;
    }
    else if (question_answer === this.answer6_el || question_answer === this.answer6_en) {
      this.questionnaire_obj5.questions[0].answer_id = 6;
      this.questionnaire_obj5.total_score = this.questionnaire_obj5.total_score + 0;
    }
    console.log(this.questionnaire_obj5);
    console.log("user_id: ", this.questionnaire_obj5.user_id);
    console.log(this.questionnaire_obj5.total_score);
    this.questionnaire_obj5.total_score_percentage = (this.questionnaire_obj5.total_score / 5) * 100;
    console.log(this.questionnaire_obj5.total_score_percentage);

    // Save questionnaire answers (post call)
    this.userService.saveQuestionnaireAnswers(this.questionnaire_obj5).pipe().subscribe({
      next: data => {
        console.log("Successful response: ", data);
        this.translate.get('Congratulations!').subscribe(res => {
          console.log(res);
          this.toastr.success(res);
        });
        this.router.navigate(['/congrats']);
      },
      error: error => {
        console.log("Handle Error: ", error);
        this.translate.get('Questionnaire completion failed!').subscribe(res => {
          console.log(res);
          this.toastr.error(res);
        });
      }
    });
  }

  /* handleTimerExpiry() {
    const selectedOption = this.StepOneForm.controls['selectedOption'].value;
    this.answerGiven.emit({ answer: selectedOption });
  } */
}
