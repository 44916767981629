<app-navbar></app-navbar>
<div class="container">
    <div class="row title-row">
        <div class="col">
            <p class="title">{{'TASKS' | translate}}</p>
        </div>
    </div>

    <div class="line">
        <hr class="long">
    </div>

    <div *ngIf="foundCompleted">
        <p class="paragraph_notasks">{{'Before receiving tasks from your doctor, it is necessary to complete all questionnaires.' | translate}}</p>
        <div class="q-icon">
            <img alt="Questionnaires icon" src="assets/images/questionnaire-icon.png">
        </div>
    </div>

    <div *ngIf="!foundCompleted">
        <p class="paragraph_notasks">{{'There are currently no tasks for you. The doctor will contact you as soon as they are ready.' | translate}}</p>
        <div class="q-icon">
            <img alt="Questionnaires icon" src="assets/images/questionnaire-icon.png">
        </div>
    </div>

    <!-- <div *ngIf="link2" class="row questions-5 justify-content-left">
        <div class="col-md-6 col-lg-4 mt-5">
          <div class="card custom-card d-flex align-items-left" style="background-color: #E9EDFB;border: none;">
            <div class="card-body">
              <h6 class="title_card">"Title1"</h6>
              <p class="paragraph_card">Paragraph1</p>
              <div>
              </div> 
            </div>
          </div>
        </div>
    </div> -->

    <!-- <div class="appreciate">
        <h6 class="title1">{{'Εstimate your nutritional consumption' | translate}}</h6>
        <p class="paragraph1">Paragraph2</p>
    </div>

    <div class="find_time">
        <h6 class="title1">{{'Find time' | translate}}</h6>
        <p class="paragraph1">Paragraph3</p>
    </div>

    <div class="make_a_list">
        <h6 class="title1">{{'Μake a list of your personal benefits' | translate}}</h6>
        <h6 class="title1">{{'that you will gain through the change' | translate}}</h6>
        <p class="paragraph1">Paragraph4</p>
    </div> -->
</div>