<app-navbar></app-navbar>
<div class="container">

    <div class="row congrats-row">
        <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4 congrats-col">
            <img class="image" alt="logo" src="assets/images/congrats.png">
        </div>
        <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8">
            <h4 class="title1">{{'Congrats!' | translate}}</h4>
            <p class="paragraph">{{"I have completed the questionnaires. Press the 'FINISH' button to" | translate}}<br>{{'validate your answers.' | translate}}</p>
            <p class="paragraph">{{'The answers to the questionnaires are not shared with anyone. The final score of the' | translate}}<br>{{'questionnaires (which is calculated in a complicated way) is linked to your' | translate}}<br>{{'medical record at the office of the doctor who attends you.' | translate}}</p>
            <button type="submit" class="btn-black" (click)="onCongrats()">{{'Finish' | translate}}</button>
        </div>
    </div>

</div>