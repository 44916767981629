import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'test-eurodiet';

  constructor(private translate: TranslateService, private router: Router) {
    translate.addLangs(['en', 'el', 'fr', 'ro', 'no']);
  }

  ngOnInit(): void {
    const userLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const availableLanguages = this.translate.getLangs();
    const selectedLanguage = availableLanguages.includes(userLanguage) ? userLanguage : 'en';

    this.translate.use(selectedLanguage);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        localStorage.setItem('selectedLanguage', this.translate.currentLang);
        this.sendGoogleAnalyticsPageView(event.urlAfterRedirects);
      }
    });
  }

  private sendGoogleAnalyticsPageView(url: string): void {
    gtag('config', 'G-M2Z4FN3K3X', {
      page_path: url
    });
  }
}