import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from './helpers/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { WorksComponent } from './works/works.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { AboutAppComponent } from './about-app/about-app.component';
import { AuthService } from './services/auth.service';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateCompiler, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import { QuestionnaireStartComponent } from './first-questionnaire-step1/questionnaire-start.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CongratsComponent } from './congrats/congrats.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AboutApp2Component } from './about-app2/about-app2.component';
import { Questions5InfoComponent } from './questions5-info/questions5-info.component';
import { Questionnaire20StartComponent } from './sixth-questionnaire-step1/questionnaire20-start.component';
import { QuestionnaireStart2Component } from './first-questionnaire-step2/questionnaire-start2.component';
import { QuestionnaireStart3Component } from './first-questionnaire-step3/questionnaire-start3.component';
import { QuestionnaireStart4Component } from './first-questionnaire-step4/questionnaire-start4.component';
import { QuestionnaireStart5Component } from './first-questionnaire-step5/questionnaire-start5.component';
import { Questionnaire20Start2Component } from './sixth-questionnaire-step2/questionnaire20-start2.component';
import { Questionnaire20Start3Component } from './sixth-questionnaire-step3/questionnaire20-start3.component';
import { Questionnaire20Start4Component } from './sixth-questionnaire-step4/questionnaire20-start4.component';
import { Questionnaire20Start5Component } from './sixth-questionnaire-step5/questionnaire20-start5.component';
import { Questionnaire20Start6Component } from './sixth-questionnaire-step6/questionnaire20-start6.component';
import { Questionnaire20Start7Component } from './sixth-questionnaire-step7/questionnaire20-start7.component';
import { Questionnaire20Start8Component } from './sixth-questionnaire-step8/questionnaire20-start8.component';
import { Questionnaire20Start9Component } from './sixth-questionnaire-step9/questionnaire20-start9.component';
import { Questionnaire20Start10Component } from './sixth-questionnaire-step10/questionnaire20-start10.component';
import { Questionnaire20Start11Component } from './sixth-questionnaire-step11/questionnaire20-start11.component';
import { Questionnaire20Start12Component } from './sixth-questionnaire-step12/questionnaire20-start12.component';
import { Questionnaire20Start13Component } from './sixth-questionnaire-step13/questionnaire20-start13.component';
import { Questionnaire20Start14Component } from './sixth-questionnaire-step14/questionnaire20-start14.component';
import { Questionnaire20Start15Component } from './sixth-questionnaire-step15/questionnaire20-start15.component';
import { Questionnaire20Start16Component } from './sixth-questionnaire-step16/questionnaire20-start16.component';
import { Questionnaire20Start17Component } from './sixth-questionnaire-step17/questionnaire20-start17.component';
import { Questionnaire20Start18Component } from './sixth-questionnaire-step18/questionnaire20-start18.component';
import { Questionnaire20Start19Component } from './sixth-questionnaire-step19/questionnaire20-start19.component';
import { Questionnaire20Start20Component } from './sixth-questionnaire-step20/questionnaire20-start20.component';
import { FifthQuestionnaireStep1Component } from './fifth-questionnaire-step1/fifth-questionnaire-step1.component';
import { FourthQuestionnaireStep1Component } from './fourth-questionnaire-step1/fourth-questionnaire-step1.component';
import { FourthQuestionnaireStep2Component } from './fourth-questionnaire-step2/fourth-questionnaire-step2.component';
import { FourthQuestionnaireStep3Component } from './fourth-questionnaire-step3/fourth-questionnaire-step3.component';
import { FourthQuestionnaireStep4Component } from './fourth-questionnaire-step4/fourth-questionnaire-step4.component';
import { FourthQuestionnaireStep5Component } from './fourth-questionnaire-step5/fourth-questionnaire-step5.component';
import { FourthQuestionnaireStep6Component } from './fourth-questionnaire-step6/fourth-questionnaire-step6.component';
import { FourthQuestionnaireStep7Component } from './fourth-questionnaire-step7/fourth-questionnaire-step7.component';
import { FourthQuestionnaireStep8Component } from './fourth-questionnaire-step8/fourth-questionnaire-step8.component';
import { FourthQuestionnaireStep9Component } from './fourth-questionnaire-step9/fourth-questionnaire-step9.component';
import { FourthQuestionnaireStep10Component } from './fourth-questionnaire-step10/fourth-questionnaire-step10.component';
import { SecondQuestionnaireStep1Component } from './second-questionnaire-step1/second-questionnaire-step1.component';
import { SecondQuestionnaireStep2Component } from './second-questionnaire-step2/second-questionnaire-step2.component';
import { SecondQuestionnaireStep3Component } from './second-questionnaire-step3/second-questionnaire-step3.component';
import { SecondQuestionnaireStep4Component } from './second-questionnaire-step4/second-questionnaire-step4.component';
import { SecondQuestionnaireStep5Component } from './second-questionnaire-step5/second-questionnaire-step5.component';
import { SecondQuestionnaireStep6Component } from './second-questionnaire-step6/second-questionnaire-step6.component';
import { SecondQuestionnaireStep7Component } from './second-questionnaire-step7/second-questionnaire-step7.component';
import { SecondQuestionnaireStep8Component } from './second-questionnaire-step8/second-questionnaire-step8.component';
import { SecondQuestionnaireStep9Component } from './second-questionnaire-step9/second-questionnaire-step9.component';
import { SecondQuestionnaireStep10Component } from './second-questionnaire-step10/second-questionnaire-step10.component';
import { SecondQuestionnaireStep11Component } from './second-questionnaire-step11/second-questionnaire-step11.component';
import { SecondQuestionnaireStep12Component } from './second-questionnaire-step12/second-questionnaire-step12.component';
import { SecondQuestionnaireStep13Component } from './second-questionnaire-step13/second-questionnaire-step13.component';
import { ThirdQuestionnaireStep1Component } from './third-questionnaire-step1/third-questionnaire-step1.component';
import { ThirdQuestionnaireStep2Component } from './third-questionnaire-step2/third-questionnaire-step2.component';
import { ThirdQuestionnaireStep3Component } from './third-questionnaire-step3/third-questionnaire-step3.component';
import { ThirdQuestionnaireStep4Component } from './third-questionnaire-step4/third-questionnaire-step4.component';
import { ThirdQuestionnaireStep5Component } from './third-questionnaire-step5/third-questionnaire-step5.component';
import { ThirdQuestionnaireStep6Component } from './third-questionnaire-step6/third-questionnaire-step6.component';
import { ThirdQuestionnaireStep7Component } from './third-questionnaire-step7/third-questionnaire-step7.component';
import { ThirdQuestionnaireStep8Component } from './third-questionnaire-step8/third-questionnaire-step8.component';
import { ThirdQuestionnaireStep9Component } from './third-questionnaire-step9/third-questionnaire-step9.component';
import { ThirdQuestionnaireStep10Component } from './third-questionnaire-step10/third-questionnaire-step10.component';
import { ThirdQuestionnaireStep11Component } from './third-questionnaire-step11/third-questionnaire-step11.component';
import { ThirdQuestionnaireStep12Component } from './third-questionnaire-step12/third-questionnaire-step12.component';
import { ThirdQuestionnaireStep13Component } from './third-questionnaire-step13/third-questionnaire-step13.component';
import { ThirdQuestionnaireStep14Component } from './third-questionnaire-step14/third-questionnaire-step14.component';
import { ThirdQuestionnaireStep15Component } from './third-questionnaire-step15/third-questionnaire-step15.component';
import { ThirdQuestionnaireStep16Component } from './third-questionnaire-step16/third-questionnaire-step16.component';
import { ThirdQuestionnaireStep17Component } from './third-questionnaire-step17/third-questionnaire-step17.component';
import { ThirdQuestionnaireStep18Component } from './third-questionnaire-step18/third-questionnaire-step18.component';
import { QuestionComponent } from './question/question.component';
import { ParentQuestionnaireOneComponent } from './parent-questionnaire-one/parent-questionnaire-one.component';
import { ForgotPassword2Component } from './forgot-password2/forgot-password2.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { Navbar2Component } from './navbar2/navbar2.component';
import { OpenModalTermsOfUseComponent } from './open-modal-terms-of-use/open-modal-terms-of-use.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    HomeComponent,
    WorksComponent,
    TermsOfUseComponent,
    AboutAppComponent,
    QuestionnaireStartComponent,
    SignupComponent,
    CongratsComponent,
    AboutApp2Component,
    Questions5InfoComponent,
    Questionnaire20StartComponent,
    QuestionnaireStart2Component,
    QuestionnaireStart3Component,
    QuestionnaireStart4Component,
    QuestionnaireStart5Component,
    Questionnaire20Start2Component,
    Questionnaire20Start3Component,
    Questionnaire20Start4Component,
    Questionnaire20Start5Component,
    Questionnaire20Start6Component,
    Questionnaire20Start7Component,
    Questionnaire20Start8Component,
    Questionnaire20Start9Component,
    Questionnaire20Start10Component,
    Questionnaire20Start11Component,
    Questionnaire20Start12Component,
    Questionnaire20Start13Component,
    Questionnaire20Start14Component,
    Questionnaire20Start15Component,
    Questionnaire20Start16Component,
    Questionnaire20Start17Component,
    Questionnaire20Start18Component,
    Questionnaire20Start19Component,
    Questionnaire20Start20Component,
    FifthQuestionnaireStep1Component,
    FourthQuestionnaireStep1Component,
    FourthQuestionnaireStep2Component,
    FourthQuestionnaireStep3Component,
    FourthQuestionnaireStep4Component,
    FourthQuestionnaireStep5Component,
    FourthQuestionnaireStep6Component,
    FourthQuestionnaireStep7Component,
    FourthQuestionnaireStep8Component,
    FourthQuestionnaireStep9Component,
    FourthQuestionnaireStep10Component,
    SecondQuestionnaireStep1Component,
    SecondQuestionnaireStep2Component,
    SecondQuestionnaireStep3Component,
    SecondQuestionnaireStep4Component,
    SecondQuestionnaireStep5Component,
    SecondQuestionnaireStep6Component,
    SecondQuestionnaireStep7Component,
    SecondQuestionnaireStep8Component,
    SecondQuestionnaireStep9Component,
    SecondQuestionnaireStep10Component,
    SecondQuestionnaireStep11Component,
    SecondQuestionnaireStep12Component,
    SecondQuestionnaireStep13Component,
    ThirdQuestionnaireStep1Component,
    ThirdQuestionnaireStep2Component,
    ThirdQuestionnaireStep3Component,
    ThirdQuestionnaireStep4Component,
    ThirdQuestionnaireStep5Component,
    ThirdQuestionnaireStep6Component,
    ThirdQuestionnaireStep7Component,
    ThirdQuestionnaireStep8Component,
    ThirdQuestionnaireStep9Component,
    ThirdQuestionnaireStep10Component,
    ThirdQuestionnaireStep11Component,
    ThirdQuestionnaireStep12Component,
    ThirdQuestionnaireStep13Component,
    ThirdQuestionnaireStep14Component,
    ThirdQuestionnaireStep15Component,
    ThirdQuestionnaireStep16Component,
    ThirdQuestionnaireStep17Component,
    ThirdQuestionnaireStep18Component,
    QuestionComponent,
    ParentQuestionnaireOneComponent,
    ForgotPassword2Component,
    ForgotPasswordComponent,
    Navbar2Component,
    OpenModalTermsOfUseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 8000,
      progressBar: false
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'el',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
    }
  }),
  ],
  providers: [
    AuthService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
