<div class="container">

  <div class="row" style="text-align: center;">
    <div class="col">
      <a [routerLink]="['#']">
        <img alt="logo" src="assets/images/evivios_med.jpg" class="login-logo">
      </a>
    </div>
  </div>

  <div style="display: flex;justify-content: center;align-items: center;">
   
    <a *ngIf="NavLink1" class="nav-link el-active" value="el" (click)="useLanguage('el')" style="border: none; cursor: default; background: #fff;margin-top: -1px"> 
      <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}
    </a>
    <a *ngIf="NavLink2" class="nav-link el" value="el" (click)="useLanguage('el')" style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}
    </a>
    
    <a *ngIf="NavLink3" class="nav-link en-active" value="en" (click)="useLanguage('en')"  style="border: none; cursor: default; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
    </a>
    <a *ngIf="NavLink4" class="nav-link en" value="en" (click)="useLanguage('en')"  style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
    </a>
    
    <a *ngIf="NavLink5" class="nav-link en-active" value="fr" (click)="useLanguage('fr')"  style="border: none; cursor: default; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/france.png" alt="France Flag" class="flag-icon"> {{'FR' | translate}}
    </a>
    <a *ngIf="NavLink6" class="nav-link en" value="fr" (click)="useLanguage('fr')"  style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/france.png" alt="France Flag" class="flag-icon"> {{'FR' | translate}}
    </a>
    
    <a *ngIf="NavLink7" class="nav-link en-active" value="ro" (click)="useLanguage('ro')"  style="border: none; cursor: default; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/romania.png" alt="Romania Flag" class="flag-icon"> {{'RO' | translate}}
    </a>
    <a *ngIf="NavLink8" class="nav-link en" value="ro" (click)="useLanguage('ro')"  style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/romania.png" alt="Romania Flag" class="flag-icon"> {{'RO' | translate}}
    </a>
    
    <a *ngIf="NavLink9" class="nav-link en-active" value="no" (click)="useLanguage('no')"  style="border: none; cursor: default; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/norway.png" alt="Norway Flag" class="flag-icon"> {{'NO' | translate}}
    </a>
    <a *ngIf="NavLink10" class="nav-link en" value="no" (click)="useLanguage('no')"  style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
      <img class="img-country" width="20px" src="assets/images/norway.png" alt="Norway Flag" class="flag-icon"> {{'NO' | translate}}
    </a>
    
  </div>

  <div class="row" style="display: flex;justify-content: center;">
    <div class="col" style="display: flex;justify-content: center;">
      <div class="login-up-but">
        <button id="login" class="login" [routerLink]="['/login']">{{'Log in' | translate}}</button>
        <button id="signup" class="signup" [routerLink]="['/signup']">{{'Sign up' | translate}}</button>
      </div>
    </div>
  </div>

  <div class="mb-3" style="display: flex;justify-content: center;">
    <p class="paragraph">{{'Because each of us is unique, your doctor has given you' | translate}}<br>{{'an individual access to this application.' | translate}}<br>{{'Enter the code given to you by your doctor and set your email' | translate}}<br>{{'and password.' | translate}}</p>
  </div>

  <form #userForm="ngForm" (NgSubmit)="userForm.valid && onSignupSubmit(userForm)">

      <div class="row" style="display: flex;justify-content: center;">
        <div class="col" style="display: flex;justify-content: center;">
          <div class="mb-3 invitation-field">
            <label for="exampleInputInvitationcode1" class="form-label">{{'Invitation code' | translate}}</label>
            <input type="text" pattern="^\S+$" class="form-control invitationcode-input" id="exampleInputInvitationcode1" name="auth_code" [(ngModel)]="user.auth_code" #auth_code="ngModel" required style="background-image: url('assets/images/locker-icon.png');">
            <small class="smallclass" *ngIf="auth_code.touched && !auth_code.valid">{{'Enter your invitation code!' | translate}}</small>
          </div>
        </div>
      </div> 

      <div class="row" style="display: flex;justify-content: center;">
        <div class="col" style="display: flex;justify-content: center;">
          <div class="mb-3 email-field">
            <label for="exampleInputEmail1" class="form-label">{{'Email' | translate}}</label>
            <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" class="form-control email-input" id="exampleInputEmail1" aria-describedby="emailHelp" name="username" [(ngModel)]="user.username" #username="ngModel" email required style="background-image: url('assets/images/email-icon.png');">
            <small class="smallclass" *ngIf="username.touched && !username.valid">{{'Enter your email address!' | translate}}</small>
            <br>
            <small class="smallclass" *ngIf="username.touched && !username.valid">{{'Must be a valid email address.' | translate}}</small>
          </div>
        </div>
      </div>
  
      <div class="row" style="display: flex;justify-content: center;">
        <div class="col" style="display: flex;justify-content: center;">
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">{{'Password' | translate}}</label>
            <input type="password" class="form-control pass-input" id="exampleInputPassword1" name="password" [(ngModel)]="user.password" #password="ngModel" minlength="6" required style="background-image: url('assets/images/key-icon.png');">
            <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Enter your password!' | translate}}</small>
            <br>
            <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Password should be at least 6 characters long.' | translate}}</small>
          </div>
        </div>
      </div> 
  
      <div class="row" style="display: flex;justify-content: center;">
        <div class="col" style="display: flex;justify-content: center;">
          <div class="mb-3">
            <button type="submit" class="btn-black" [disabled]="!userForm.form.valid" (click)="onSignupSubmit(userForm)">{{'SΙGN UP' | translate}}</button>
          </div>
        </div>
      </div>
  </form>

  <!-- <div class="row">
    <div class="col" style="padding-left: 0px;">
      <div class="div-btn-forgot">
        <a class="forget" [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{exact:true}">{{'Forgot password' | translate}}</a>
      </div>
    </div>
  </div> -->

  <!-- <div class="row" style="text-align: center;">
    <div class="col">
      <div class="btn-popup">
        <button class="about" (click)="openModal()">{{'About the application' | translate}}</button>
      </div>
    </div>
  </div> -->
  
</div>