<nav class="navbar navbar-expand-lg navbar-light bg-light"> <!-- fixed-top -->
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['/about-app']" (click)="collapseNav()">
            <img class="img-logo" alt="Evivios logo" src="assets/images/evivios_med.png">
        </a>
        <button #navbarToggler class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbarCollapsing()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div [class.collapse]="navbarCollapsed" class="collapse navbar-collapse all-item-mdiv" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-auto all-item-mul">
                <li class="nav-item navbar-center">
                    <a class="nav-link menu" [routerLink]="['/home']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}" style="margin-right: 10px;" (click)="collapseNav()">{{'QUESTIONNAIRES' | translate}}</a>
                </li>
                <li class="nav-item navbar-center">
                    <a class="nav-link menu" [routerLink]="['/about-app']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}" style="margin-right: 10px;" (click)="collapseNav()">{{'ABOUT THE APPLICATION' | translate}}</a>
                </li>
                <li class="nav-item navbar-center">
                    <a class="nav-link menu" [routerLink]="['/terms']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}" (click)="collapseNav()">{{'TERMS OF USE' | translate}}</a>
                </li>
            </ul>
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-auto">
                <li class="nav-item">
                    <button class="image-button" [routerLink]="['/']" data-toggle="tooltip" data-placement="bottom" title="{{'Log out' | translate}}" (click)="logoutUser()">
                        <img class="log-out-image" src="assets/images/log-out.png">
                    </button>
                </li>

                <!-- old solution for languages -->
                <!-- <li class="nav-item">
                    <a *ngIf="NavLink1" class="nav-link el-active" value="el" (click)="useLanguage('el')" style="border: none; background: #fff;margin-top: -1px"> 
                        <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}</a>
                    <a *ngIf="NavLink2" class="nav-link el" value="el" (click)="useLanguage('el')" style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a *ngIf="NavLink3" class="nav-link en-active" value="en" (click)="useLanguage('en')"  style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
                    </a>
                    <a *ngIf="NavLink4" class="nav-link en" value="en" (click)="useLanguage('en')"  style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="NavLink5" class="nav-link en-active" value="fr" (click)="useLanguage('fr')"  style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/france.png" alt="France Flag" class="flag-icon"> {{'FR' | translate}}
                    </a>
                    <a *ngIf="NavLink6" class="nav-link en" value="fr" (click)="useLanguage('fr')"  style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/france.png" alt="France Flag" class="flag-icon"> {{'FR' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="NavLink7" class="nav-link en-active" value="ro" (click)="useLanguage('ro')"  style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/romania.png" alt="Romania Flag" class="flag-icon"> {{'RO' | translate}}
                    </a>
                    <a *ngIf="NavLink8" class="nav-link en" value="ro" (click)="useLanguage('ro')"  style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/romania.png" alt="Romania Flag" class="flag-icon"> {{'RO' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="NavLink9" class="nav-link en-active" value="no" (click)="useLanguage('no')"  style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/norway.png" alt="Norway Flag" class="flag-icon"> {{'NO' | translate}}
                    </a>
                    <a *ngIf="NavLink10" class="nav-link en" value="no" (click)="useLanguage('no')"  style="border: none; background: #fff;margin-top: -1px">
                        <img class="img-country" width="20px" src="assets/images/norway.png" alt="Norway Flag" class="flag-icon"> {{'NO' | translate}}
                    </a>
                </li> -->

                <!-- new solution for languages -->
                <li class="nav-item">
                    <a *ngIf="translate.currentLang === 'el'" class="nav-link el-active" style="border: none; background: #fff;margin-top: -1px;cursor: auto"> 
                        <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a *ngIf="translate.currentLang === 'en'" class="nav-link en-active" style="border: none; background: #fff;margin-top: -1px;cursor: auto">
                        <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="translate.currentLang === 'fr'" class="nav-link en-active" style="border: none; background: #fff;margin-top: -1px;cursor: auto">
                        <img class="img-country" width="20px" src="assets/images/france.png" alt="France Flag" class="flag-icon"> {{'FR' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="translate.currentLang === 'ro'" class="nav-link en-active" style="border: none; background: #fff;margin-top: -1px;cursor: auto">
                        <img class="img-country" width="20px" src="assets/images/romania.png" alt="Romania Flag" class="flag-icon"> {{'RO' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="translate.currentLang === 'no'" class="nav-link en-active" style="border: none; background: #fff;margin-top: -1px;cursor: auto">
                        <img class="img-country" width="20px" src="assets/images/norway.png" alt="Norway Flag" class="flag-icon"> {{'NO' | translate}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>