import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public questionnaire_items_all: any = [];

  public question_id: any = [];

  loadedQuestionnaires = false;

  language: string = "";

  emptyQuestionnaires = false;

  public congratsText: any = [];

  onMessage: string = "";

  EmptyQuestionnaire_Message: string = "";

  constructor(private router: Router,
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    /* console.log("LANGUAGE: ", this.translate.currentLang); */

    if (this.translate.currentLang === 'el') {
      this.language = 'GR'
    }
    else if (this.translate.currentLang === 'en') {
      this.language = 'EN'
    }
    else if (this.translate.currentLang === 'fr') {
      this.language = 'FR'
    }
    else if (this.translate.currentLang === 'ro') {
      this.language = 'RO'
    }
    else {
      this.language = 'NO'
    }

    // the first way to check if the user has completed the questionnaires
    this.userService.getQuestionnairesNew(this.language).pipe().subscribe({
      next: data => {
        // Φόρτωση όλων των questionnaire_items_all
        this.loadedQuestionnaires = true;

        /* console.log("ALL QUESTIONNAIRES:", data); */

        const all_questionnaires = data["_items"];

        // αποθήκευση τιμών στο localStorage
        localStorage.setItem('all_questionnaires', JSON.stringify(all_questionnaires));

        if (all_questionnaires.length === 0) {
          this.emptyQuestionnaires = true;
          /* console.log("Μessage:", data.message); */
          /* this.EmptyQuestionnaire_Message = data.message; */
        }

        //dynamic try
        this.questionnaire_items_all = all_questionnaires;
        /* console.log("ALL QUESTIONNAIRES EL:", this.questionnaire_items_all);
        console.log("ALL QUESTIONNAIRES EL LENGTH:", this.questionnaire_items_all.length); */
        for (let i = 0; i < this.questionnaire_items_all.length; i++) {
          this.question_id[i] = this.questionnaire_items_all[i].items.length;
          /* console.log("QUESTION ids:", this.question_id); */
        }
        // dynamic try end
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });

    // παίρνουμε δυναμικά το κείμενο για το message, το οποίο θα εμαφανίζεται όταν ο χρήστης έχει απαντήσει τα ερωτηματολόγια και έχει ολοκληρωθεί η διαδικασία
    /* this.userService.getCongratsText(this.language).pipe().subscribe({
      next: data => {
        this.congratsText = data;
        console.log("congrats_text: ", this.congratsText);
      },
      error: error => {
        console.log("Handle Error: ", error);
        // Handle Error Here
      }
    }); */

    // παίρνουμε όλες τις απαντήσεις του χρήστη για τα ερωτηματολόγια που έχει απαντήσει
    // το κάνουμε για έμας για testing αν γίνονται σωστά τα post calls που κάνουμε
    /* this.userService.getQuestionnaireAnswers().pipe().subscribe({
      next: data => {
        console.log("ANSWERS:", data);
      },
      error: error => {
        console.log("Handle Error: ", error);
        // Handle Error Here
      }
    }); */

    /* Ή */
    // Ανάκτηση της τιμής της παραμέτρου 'message' από το URL
    /* this.activatedRoute.queryParams.subscribe(params => {
      const congrats_message = params['message'];
      console.log("Message:", congrats_message);
      this.onMessage = congrats_message;
    }); */
  }

  onclick(i: number) {
    /* console.log("questionnaire _id: ", this.questionnaire_items_all[i]._id); */
    this.router.navigate(['/questions-info/' + this.questionnaire_items_all[i]._id]);
  }

  // the second way to check if the user has completed the questionnaires (put it in ngOnit if you want to use it.)

  /* this.userService.getQuestionnaireAnswers().pipe().subscribe({
    next: data => {
      console.log(data);

      const hasQuestionnaireOne = data["_items"].some(
        (item: { questionnaire_id: string }) => item.questionnaire_id === "1"
      );
      console.log(hasQuestionnaireOne);
      this.link1 = !hasQuestionnaireOne;
      this.link2 = hasQuestionnaireOne;
      console.log(this.link1);
      console.log(this.link2);

      const hasQuestionnaireTwo = data["_items"].some(
        (item: { questionnaire_id: string }) => item.questionnaire_id === "2"
      );
      console.log(hasQuestionnaireTwo);
      if (!hasQuestionnaireTwo && this.link1 === false && this.link2 === true) {
        this.link3 = true;
        this.link4 = false;
      }
      if (hasQuestionnaireTwo && this.link2 === true) {
        this.link3 = false;
        this.link4 = true;
      }
      console.log(this.link3);
      console.log(this.link4);

      const hasQuestionnaireThree = data["_items"].some(
        (item: { questionnaire_id: string }) => item.questionnaire_id === "3"
      );
      console.log(hasQuestionnaireThree);
      if (hasQuestionnaireThree === true && this.link2 === true && this.link4 === true) {
        this.link5 = false;
        this.link6 = true;
      }
      if (hasQuestionnaireThree === false && this.link2 === true && this.link4 === true) {
        this.link5 = true;
        this.link6 = false;
      }
      console.log(this.link5);
      console.log(this.link6);

      const hasQuestionnaireFour = data["_items"].some(
        (item: { questionnaire_id: string }) => item.questionnaire_id === "4"
      );
      console.log(hasQuestionnaireFour);
      if (hasQuestionnaireFour === true && this.link2 === true && this.link4 === true && this.link6 === true) {
        this.link7 = false;
        this.link8 = true;
      }
      if (hasQuestionnaireFour === false && this.link2 === true && this.link4 === true && this.link6 === true) {
        this.link7 = true;
        this.link8 = false;
      }
      console.log(this.link7);
      console.log(this.link8);

      const hasQuestionnaireFive = data["_items"].some(
        (item: { questionnaire_id: string }) => item.questionnaire_id === "5"
      );
      console.log(hasQuestionnaireFive);
      if (hasQuestionnaireFive === true && this.link2 === true && this.link4 === true && this.link6 === true && this.link8 === true) {
        this.link9 = false;
        this.link10 = true;
      }
      if (hasQuestionnaireFive === false && this.link2 === true && this.link4 === true && this.link6 === true && this.link8 === true) {
        this.link9 = true;
        this.link10 = false;
      }
      console.log(this.link9);
      console.log(this.link10);

      const hasQuestionnaireSix = data["_items"].some(
        (item: { questionnaire_id: string }) => item.questionnaire_id === "6"
      );
      console.log(hasQuestionnaireSix);
      if (hasQuestionnaireSix === true && this.link2 === true && this.link4 === true && this.link6 === true && this.link8 === true && this.link10 === true) {
        this.link11 = false;
        this.link12 = true;
      }
      if (hasQuestionnaireSix === false && this.link2 === true && this.link4 === true && this.link6 === true && this.link8 === true && this.link10 === true) {
        this.link11 = true;
        this.link12 = false;
      }
      console.log(this.link11);
      console.log(this.link12);

    },
    error: error => {
      console.log("Handle Error: ", error);
      // Handle Error Here
    }
  }); */
}