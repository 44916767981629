import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerService } from '../services/data-layer.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-third-questionnaire-step15',
  templateUrl: './third-questionnaire-step15.component.html',
  styleUrls: ['./third-questionnaire-step15.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(300)
      ]),
      transition('* => void', [
        animate(300, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class ThirdQuestionnaireStep15Component implements OnInit {
  targetDate!: Date;
  timeLeft: any;

  options_el = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  options_en = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  @Input('questionnaire_obj3') public questionnaire_obj3: any = {};

  /* @Input() timeLeft!: any; */

  public selectedOption = '';

  public displayTime = '01:00';
  private timer!: any;

  public StepFiveteenForm: FormGroup;

  optionSelected = false;

  question15_el = '';
  question15_en = '';

  answer1_el = '';
  answer1_en = '';
  answer2_el = '';
  answer2_en = '';
  answer3_el = '';
  answer3_en = '';
  answer4_el = '';
  answer4_en = '';

  all_questions_length = 0;
  question_length = 0;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    public dataLayerService: DataLayerService,
    public translate: TranslateService,
    private fb: FormBuilder
  ) {
    this.StepFiveteenForm = this.fb.group({
      selectedOption: [this.selectedOption, Validators.compose([Validators.required])]
    });
  }

  ngOnInit(): void {
    // για να μη μπορεί να πατήσει το κουμπί 'πίσω' απ τον browser και να πάει στο step14
    const currentPath = window.location.pathname;

    if (currentPath === '/third-step15') {
      /* console.log("HELLO"); */
      history.pushState('', '', location.href);
      window.onpopstate = function () {
        history.go(1);
      };
    }

    // timeLeft
    this.targetDate = new Date();
    this.targetDate.setMinutes(this.targetDate.getMinutes() + 10);
    this.timer = setInterval(() => {
      this.timeLeft = this.calculateTimeLeft();
    }, 1000);

    // ανάκτηση τιμών από το localStorage
    this.questionnaire_obj3 = JSON.parse(localStorage.getItem('questionnaire_obj3') || '{}');
    console.log(this.questionnaire_obj3);

    // ανάκτηση τιμών από το localStorage
    const allDataQuestionnaires = JSON.parse(localStorage.getItem('all_questionnaires') || '{}');

    // θέλουμε τo 3ο ερωτηματολόγιο (που έχει 18 ερωτήσεις) άρα:
    console.log(allDataQuestionnaires[2]);

    this.questionnaire_obj3.questions[14].question_id = allDataQuestionnaires[2].items[14].question_id;
    this.question_length = allDataQuestionnaires[2].items[14].question_id;
    this.all_questions_length = allDataQuestionnaires[2].items.length;

    this.question15_el = allDataQuestionnaires[2].items[14].question_el;
    this.question15_en = allDataQuestionnaires[2].items[14].question_en;

    this.answer1_el = allDataQuestionnaires[2].answers[8].answer_el;
    this.answer1_en = allDataQuestionnaires[2].answers[8].answer_en;
    this.answer2_el = allDataQuestionnaires[2].answers[9].answer_el;
    this.answer2_en = allDataQuestionnaires[2].answers[9].answer_en;
    this.answer3_el = allDataQuestionnaires[2].answers[10].answer_el;
    this.answer3_en = allDataQuestionnaires[2].answers[10].answer_en;
    this.answer4_el = allDataQuestionnaires[2].answers[7].answer_el;
    this.answer4_en = allDataQuestionnaires[2].answers[7].answer_en;

    this.options_el[0] = this.answer1_el;
    this.options_el[1] = this.answer2_el;
    this.options_el[2] = this.answer3_el;
    this.options_el[3] = this.answer4_el;

    this.options_en[0] = this.answer1_en;
    this.options_en[1] = this.answer2_en;
    this.options_en[2] = this.answer3_en;
    this.options_en[3] = this.answer4_en;

    this.StepFiveteenForm = this.fb.group({
      selectedOption: [this.selectedOption, Validators.compose([Validators.required])]
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  calculateTimeLeft() {
    let difference = +this.targetDate - +new Date();
    let minutesLeft = Math.floor(difference / (1000 * 60));
    let secondsLeft = Math.floor((difference / 1000) % 60);

    this.displayTime = ('0' + minutesLeft).slice(-2) + ':' + ('0' + secondsLeft).slice(-2);

    if (this.displayTime === '00:00') {
      this.translate.get('Time is up!').subscribe(res => {
        console.log(res);
        this.toastr.error(res);
      });
      clearInterval(this.timer);
      this.questionnaire_obj3 = {};
      console.log(this.questionnaire_obj3);
      this.router.navigate(['/home']);
    }
    return this.displayTime;
  }

  getSecondsAsDigitalClock(minutes: number) {
    let sec_num = minutes * 60;
    let minutesString = Math.floor(minutes).toString();
    let secondsString = '';

    this.displayTime = minutesString + ':00';

    this.timer = setInterval(() => {
      sec_num--;
      let minutes = Math.floor(sec_num / 60);
      let seconds = sec_num - minutes * 60;

      if (seconds < 10) {
        secondsString = '0' + seconds;
      } else {
        secondsString = seconds.toString();
      }

      this.displayTime = minutesString + ':' + secondsString;

      if (sec_num === 0) {
        clearInterval(this.timer);
        /* this.handleTimerExpiry(); */
      }
    }, 1000);
  }

  onAnswerSelected(selectedOption: string) {
    this.selectedOption = selectedOption;
    this.optionSelected = true;
  }

  onAnswerSubmitted() {
    clearInterval(this.timer);
    const question_answer = this.StepFiveteenForm.controls['selectedOption'].value;
    console.log(question_answer);
    if (question_answer === this.answer1_el || question_answer === this.answer1_en) {
      this.questionnaire_obj3.questions[14].answer_id = 9;
      this.questionnaire_obj3.total_score = this.questionnaire_obj3.total_score + 1;
    }
    else if (question_answer === this.answer2_el || question_answer === this.answer2_en) {
      this.questionnaire_obj3.questions[14].answer_id = 10;
      this.questionnaire_obj3.total_score = this.questionnaire_obj3.total_score + 2;
    }
    else if (question_answer === this.answer3_el || question_answer === this.answer3_en) {
      this.questionnaire_obj3.questions[14].answer_id = 11;
      this.questionnaire_obj3.total_score = this.questionnaire_obj3.total_score + 3;
    }
    else if (question_answer === this.answer4_el || question_answer === this.answer4_en) {
      this.questionnaire_obj3.questions[14].answer_id = 8;
      this.questionnaire_obj3.total_score = this.questionnaire_obj3.total_score + 4;
    }
    console.log(this.questionnaire_obj3);

    // αποθήκευση τιμών στο localStorage
    localStorage.setItem('questionnaire_obj3', JSON.stringify(this.questionnaire_obj3));

    this.router.navigate(['/third-step16']);
  }

  /* handleTimerExpiry() {
    const selectedOption = this.StepOneForm.controls['selectedOption'].value;
    this.answerGiven.emit({ answer: selectedOption });
  } */
}
