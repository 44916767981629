<app-navbar></app-navbar>
<div class="container">

    <div class="row title-row">
        <div class="col">
            <p class="title" translate>Questionnaire</p>
        </div>
    </div>

    <div class="line">
        <hr class="long">
    </div>

    <div class="row">
        <div class="col">
            <p class="title2" translate>A few pointers before you start</p>
        </div>
    </div>
    
    <div class="row">
        <div class="col-sm-1 col-md-6 col-lg-5 col-xl-4">
            <img class="image" alt="logo" src="assets/images/instructions.png">
        </div>
        <div class="col-sm-11 col-md-6 col-lg-7 col-xl-8">
            <p class="title3">{{questionnaire_name}}</p>
            
            <!-- <p class="paragraph" *ngIf="translate.currentLang === 'el'"><img class="image-respo" alt="logo" src="assets/images/instructions.png">{{instructions_el}}<p>
            <p class="paragraph" *ngIf="translate.currentLang === 'en'"><img class="image-respo" alt="logo" src="assets/images/instructions.png">{{instructions_en}}<p> -->

            <!-- αυτό θα χρησιμοποιηθει  οταν γινει η αλλαγη του get request και το get θα μου επιστρεφει ερωτηματολογια μονο μιας γλωσσας -->
            <p class="paragraph"><img class="image-respo" alt="logo" src="assets/images/instructions.png">{{instructions}}<p></p>

            <p *ngIf="emptyInstructions" class="paragraph"><img class="image-respo" alt="logo" src="assets/images/instructions.png"><p>{{"There are currently no instructions available for this questionnaire." | translate}}</p>
            <div class="col-sm-11 col-md-6 col-lg-7 col-xl-8">
                <button type="submit" class="btn-black" (click)="onclick()" translate>Continue</button>
            </div>
        </div>
    </div>

</div>