import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AuthGuard } from './helpers/auth.guard';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { AboutAppComponent } from './about-app/about-app.component';
import { CongratsComponent } from './congrats/congrats.component';
import { Questions5InfoComponent } from './questions5-info/questions5-info.component';
import { QuestionnaireStartComponent } from './first-questionnaire-step1/questionnaire-start.component';
import { QuestionnaireStart2Component } from './first-questionnaire-step2/questionnaire-start2.component';
import { QuestionnaireStart3Component } from './first-questionnaire-step3/questionnaire-start3.component';
import { QuestionnaireStart4Component } from './first-questionnaire-step4/questionnaire-start4.component';
import { QuestionnaireStart5Component } from './first-questionnaire-step5/questionnaire-start5.component';
import { SecondQuestionnaireStep1Component } from './second-questionnaire-step1/second-questionnaire-step1.component';
import { SecondQuestionnaireStep2Component } from './second-questionnaire-step2/second-questionnaire-step2.component';
import { SecondQuestionnaireStep3Component } from './second-questionnaire-step3/second-questionnaire-step3.component';
import { SecondQuestionnaireStep4Component } from './second-questionnaire-step4/second-questionnaire-step4.component';
import { SecondQuestionnaireStep5Component } from './second-questionnaire-step5/second-questionnaire-step5.component';
import { SecondQuestionnaireStep6Component } from './second-questionnaire-step6/second-questionnaire-step6.component';
import { SecondQuestionnaireStep7Component } from './second-questionnaire-step7/second-questionnaire-step7.component';
import { SecondQuestionnaireStep8Component } from './second-questionnaire-step8/second-questionnaire-step8.component';
import { SecondQuestionnaireStep9Component } from './second-questionnaire-step9/second-questionnaire-step9.component';
import { SecondQuestionnaireStep10Component } from './second-questionnaire-step10/second-questionnaire-step10.component';
import { SecondQuestionnaireStep11Component } from './second-questionnaire-step11/second-questionnaire-step11.component';
import { SecondQuestionnaireStep12Component } from './second-questionnaire-step12/second-questionnaire-step12.component';
import { SecondQuestionnaireStep13Component } from './second-questionnaire-step13/second-questionnaire-step13.component';
import { ThirdQuestionnaireStep1Component } from './third-questionnaire-step1/third-questionnaire-step1.component';
import { ThirdQuestionnaireStep2Component } from './third-questionnaire-step2/third-questionnaire-step2.component';
import { ThirdQuestionnaireStep3Component } from './third-questionnaire-step3/third-questionnaire-step3.component';
import { ThirdQuestionnaireStep4Component } from './third-questionnaire-step4/third-questionnaire-step4.component';
import { ThirdQuestionnaireStep5Component } from './third-questionnaire-step5/third-questionnaire-step5.component';
import { ThirdQuestionnaireStep6Component } from './third-questionnaire-step6/third-questionnaire-step6.component';
import { ThirdQuestionnaireStep7Component } from './third-questionnaire-step7/third-questionnaire-step7.component';
import { ThirdQuestionnaireStep8Component } from './third-questionnaire-step8/third-questionnaire-step8.component';
import { ThirdQuestionnaireStep9Component } from './third-questionnaire-step9/third-questionnaire-step9.component';
import { ThirdQuestionnaireStep10Component } from './third-questionnaire-step10/third-questionnaire-step10.component';
import { ThirdQuestionnaireStep11Component } from './third-questionnaire-step11/third-questionnaire-step11.component';
import { ThirdQuestionnaireStep12Component } from './third-questionnaire-step12/third-questionnaire-step12.component';
import { ThirdQuestionnaireStep13Component } from './third-questionnaire-step13/third-questionnaire-step13.component';
import { ThirdQuestionnaireStep14Component } from './third-questionnaire-step14/third-questionnaire-step14.component';
import { ThirdQuestionnaireStep15Component } from './third-questionnaire-step15/third-questionnaire-step15.component';
import { ThirdQuestionnaireStep16Component } from './third-questionnaire-step16/third-questionnaire-step16.component';
import { ThirdQuestionnaireStep17Component } from './third-questionnaire-step17/third-questionnaire-step17.component';
import { ThirdQuestionnaireStep18Component } from './third-questionnaire-step18/third-questionnaire-step18.component';
import { FourthQuestionnaireStep1Component } from './fourth-questionnaire-step1/fourth-questionnaire-step1.component';
import { FourthQuestionnaireStep2Component } from './fourth-questionnaire-step2/fourth-questionnaire-step2.component';
import { FourthQuestionnaireStep3Component } from './fourth-questionnaire-step3/fourth-questionnaire-step3.component';
import { FourthQuestionnaireStep4Component } from './fourth-questionnaire-step4/fourth-questionnaire-step4.component';
import { FourthQuestionnaireStep5Component } from './fourth-questionnaire-step5/fourth-questionnaire-step5.component';
import { FourthQuestionnaireStep6Component } from './fourth-questionnaire-step6/fourth-questionnaire-step6.component';
import { FourthQuestionnaireStep7Component } from './fourth-questionnaire-step7/fourth-questionnaire-step7.component';
import { FourthQuestionnaireStep8Component } from './fourth-questionnaire-step8/fourth-questionnaire-step8.component';
import { FourthQuestionnaireStep9Component } from './fourth-questionnaire-step9/fourth-questionnaire-step9.component';
import { FourthQuestionnaireStep10Component } from './fourth-questionnaire-step10/fourth-questionnaire-step10.component';
import { FifthQuestionnaireStep1Component } from './fifth-questionnaire-step1/fifth-questionnaire-step1.component';
import { Questionnaire20StartComponent } from './sixth-questionnaire-step1/questionnaire20-start.component';
import { Questionnaire20Start2Component } from './sixth-questionnaire-step2/questionnaire20-start2.component';
import { Questionnaire20Start3Component } from './sixth-questionnaire-step3/questionnaire20-start3.component';
import { Questionnaire20Start4Component } from './sixth-questionnaire-step4/questionnaire20-start4.component';
import { Questionnaire20Start5Component } from './sixth-questionnaire-step5/questionnaire20-start5.component';
import { Questionnaire20Start6Component } from './sixth-questionnaire-step6/questionnaire20-start6.component';
import { Questionnaire20Start7Component } from './sixth-questionnaire-step7/questionnaire20-start7.component';
import { Questionnaire20Start8Component } from './sixth-questionnaire-step8/questionnaire20-start8.component';
import { Questionnaire20Start9Component } from './sixth-questionnaire-step9/questionnaire20-start9.component';
import { Questionnaire20Start10Component } from './sixth-questionnaire-step10/questionnaire20-start10.component';
import { Questionnaire20Start11Component } from './sixth-questionnaire-step11/questionnaire20-start11.component';
import { Questionnaire20Start12Component } from './sixth-questionnaire-step12/questionnaire20-start12.component';
import { Questionnaire20Start13Component } from './sixth-questionnaire-step13/questionnaire20-start13.component';
import { Questionnaire20Start14Component } from './sixth-questionnaire-step14/questionnaire20-start14.component';
import { Questionnaire20Start15Component } from './sixth-questionnaire-step15/questionnaire20-start15.component';
import { Questionnaire20Start16Component } from './sixth-questionnaire-step16/questionnaire20-start16.component';
import { Questionnaire20Start17Component } from './sixth-questionnaire-step17/questionnaire20-start17.component';
import { Questionnaire20Start18Component } from './sixth-questionnaire-step18/questionnaire20-start18.component';
import { Questionnaire20Start19Component } from './sixth-questionnaire-step19/questionnaire20-start19.component';
import { Questionnaire20Start20Component } from './sixth-questionnaire-step20/questionnaire20-start20.component';
import { ParentQuestionnaireOneComponent } from './parent-questionnaire-one/parent-questionnaire-one.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPassword2Component } from './forgot-password2/forgot-password2.component';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  /* { path: 'signup', component: SignupComponent }, */
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  /* { path: 'forgot-pass', component: ForgotPasswordComponent },
  { path: 'forgot-pass2', component: ForgotPassword2Component }, */
  { path: 'terms', component: TermsOfUseComponent, canActivate: [AuthGuard] },
  { path: 'about-app', component: AboutAppComponent, canActivate: [AuthGuard] },
  /* { path: 'first-step1', component: QuestionnaireStartComponent, canActivate: [AuthGuard] },
  { path: 'first-step2', component: QuestionnaireStart2Component, canActivate: [AuthGuard]},
  { path: 'first-step3', component: QuestionnaireStart3Component, canActivate: [AuthGuard] },
  { path: 'first-step4', component: QuestionnaireStart4Component, canActivate: [AuthGuard] },
  { path: 'first-step5', component: QuestionnaireStart5Component, canActivate: [AuthGuard] }, */
  /* { path: 'second-step1', component: SecondQuestionnaireStep1Component, canActivate: [AuthGuard] },
  { path: 'second-step2', component: SecondQuestionnaireStep2Component, canActivate: [AuthGuard] },
  { path: 'second-step3', component: SecondQuestionnaireStep3Component, canActivate: [AuthGuard] },
  { path: 'second-step4', component: SecondQuestionnaireStep4Component, canActivate: [AuthGuard] },
  { path: 'second-step5', component: SecondQuestionnaireStep5Component, canActivate: [AuthGuard] },
  { path: 'second-step6', component: SecondQuestionnaireStep6Component, canActivate: [AuthGuard] },
  { path: 'second-step7', component: SecondQuestionnaireStep7Component, canActivate: [AuthGuard] },
  { path: 'second-step8', component: SecondQuestionnaireStep8Component, canActivate: [AuthGuard] },
  { path: 'second-step9', component: SecondQuestionnaireStep9Component, canActivate: [AuthGuard] },
  { path: 'second-step10', component: SecondQuestionnaireStep10Component, canActivate: [AuthGuard] },
  { path: 'second-step11', component: SecondQuestionnaireStep11Component, canActivate: [AuthGuard] },
  { path: 'second-step12', component: SecondQuestionnaireStep12Component, canActivate: [AuthGuard] },
  { path: 'second-step13', component: SecondQuestionnaireStep13Component, canActivate: [AuthGuard] },
  { path: 'third-step1', component: ThirdQuestionnaireStep1Component, canActivate: [AuthGuard] },
  { path: 'third-step2', component: ThirdQuestionnaireStep2Component, canActivate: [AuthGuard] },
  { path: 'third-step3', component: ThirdQuestionnaireStep3Component, canActivate: [AuthGuard] },
  { path: 'third-step4', component: ThirdQuestionnaireStep4Component, canActivate: [AuthGuard] },
  { path: 'third-step5', component: ThirdQuestionnaireStep5Component, canActivate: [AuthGuard] },
  { path: 'third-step6', component: ThirdQuestionnaireStep6Component, canActivate: [AuthGuard] },
  { path: 'third-step7', component: ThirdQuestionnaireStep7Component, canActivate: [AuthGuard] },
  { path: 'third-step8', component: ThirdQuestionnaireStep8Component, canActivate: [AuthGuard] },
  { path: 'third-step9', component: ThirdQuestionnaireStep9Component, canActivate: [AuthGuard] },
  { path: 'third-step10', component: ThirdQuestionnaireStep10Component, canActivate: [AuthGuard] },
  { path: 'third-step11', component: ThirdQuestionnaireStep11Component, canActivate: [AuthGuard] },
  { path: 'third-step12', component: ThirdQuestionnaireStep12Component, canActivate: [AuthGuard] },
  { path: 'third-step13', component: ThirdQuestionnaireStep13Component, canActivate: [AuthGuard] },
  { path: 'third-step14', component: ThirdQuestionnaireStep14Component, canActivate: [AuthGuard] },
  { path: 'third-step15', component: ThirdQuestionnaireStep15Component, canActivate: [AuthGuard] },
  { path: 'third-step16', component: ThirdQuestionnaireStep16Component, canActivate: [AuthGuard] },
  { path: 'third-step17', component: ThirdQuestionnaireStep17Component, canActivate: [AuthGuard] },
  { path: 'third-step18', component: ThirdQuestionnaireStep18Component, canActivate: [AuthGuard] },
  { path: 'fourth-step1', component: FourthQuestionnaireStep1Component, canActivate: [AuthGuard] },
  { path: 'fourth-step2', component: FourthQuestionnaireStep2Component, canActivate: [AuthGuard] },
  { path: 'fourth-step3', component: FourthQuestionnaireStep3Component, canActivate: [AuthGuard] },
  { path: 'fourth-step4', component: FourthQuestionnaireStep4Component, canActivate: [AuthGuard] },
  { path: 'fourth-step5', component: FourthQuestionnaireStep5Component, canActivate: [AuthGuard] },
  { path: 'fourth-step6', component: FourthQuestionnaireStep6Component, canActivate: [AuthGuard] },
  { path: 'fourth-step7', component: FourthQuestionnaireStep7Component, canActivate: [AuthGuard] },
  { path: 'fourth-step8', component: FourthQuestionnaireStep8Component, canActivate: [AuthGuard] },
  { path: 'fourth-step9', component: FourthQuestionnaireStep9Component, canActivate: [AuthGuard] },
  { path: 'fourth-step10', component: FourthQuestionnaireStep10Component, canActivate: [AuthGuard] },
  { path: 'fifth-step1', component: FifthQuestionnaireStep1Component, canActivate: [AuthGuard] },
  { path: 'sixth-step1', component: Questionnaire20StartComponent, canActivate: [AuthGuard] },
  { path: 'sixth-step2', component: Questionnaire20Start2Component, canActivate: [AuthGuard] },
  { path: 'sixth-step3', component: Questionnaire20Start3Component, canActivate: [AuthGuard] },
  { path: 'sixth-step4', component: Questionnaire20Start4Component, canActivate: [AuthGuard] },
  { path: 'sixth-step5', component: Questionnaire20Start5Component, canActivate: [AuthGuard] },
  { path: 'sixth-step6', component: Questionnaire20Start6Component, canActivate: [AuthGuard] },
  { path: 'sixth-step7', component: Questionnaire20Start7Component, canActivate: [AuthGuard] },
  { path: 'sixth-step8', component: Questionnaire20Start8Component, canActivate: [AuthGuard] },
  { path: 'sixth-step9', component: Questionnaire20Start9Component, canActivate: [AuthGuard] },
  { path: 'sixth-step10', component: Questionnaire20Start10Component, canActivate: [AuthGuard] },
  { path: 'sixth-step11', component: Questionnaire20Start11Component, canActivate: [AuthGuard] },
  { path: 'sixth-step12', component: Questionnaire20Start12Component, canActivate: [AuthGuard] },
  { path: 'sixth-step13', component: Questionnaire20Start13Component, canActivate: [AuthGuard] },
  { path: 'sixth-step14', component: Questionnaire20Start14Component, canActivate: [AuthGuard] },
  { path: 'sixth-step15', component: Questionnaire20Start15Component, canActivate: [AuthGuard] },
  { path: 'sixth-step16', component: Questionnaire20Start16Component, canActivate: [AuthGuard] },
  { path: 'sixth-step17', component: Questionnaire20Start17Component, canActivate: [AuthGuard] },
  { path: 'sixth-step18', component: Questionnaire20Start18Component, canActivate: [AuthGuard] },
  { path: 'sixth-step19', component: Questionnaire20Start19Component, canActivate: [AuthGuard] },
  { path: 'sixth-step20', component: Questionnaire20Start20Component, canActivate: [AuthGuard] }, */
  /* { path: 'works', component: WorksComponent, canActivate: [AuthGuard] }, */
  { path: 'congrats', component: CongratsComponent, canActivate: [AuthGuard] },
  { path: 'questions-info/:id', component: Questions5InfoComponent, canActivate: [AuthGuard] },
  { path: 'parent-questionnaire-one/:id', component: ParentQuestionnaireOneComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: 'home' }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }