import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ValidateEmailService } from 'src/app/services/validate-email.service';
import { OpenModalTermsOfUseComponent } from '../open-modal-terms-of-use/open-modal-terms-of-use.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: any = {
    /* username: '',
    password: '' */
    auth_code: ''
  };

  isTermsChecked: boolean = false;

  modalRef: BsModalRef | undefined = undefined;

  public NavLink1: boolean = false;
  public NavLink2: boolean = false;
  public NavLink3: boolean = false;
  public NavLink4: boolean = false;
  public NavLink5: boolean = false;
  public NavLink6: boolean = false;
  public NavLink7: boolean = false;
  public NavLink8: boolean = false;
  public NavLink9: boolean = false;
  public NavLink10: boolean = false;

  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private modalService: BsModalService,
    private ValidateEmailService: ValidateEmailService,
    public translate: TranslateService
  ) { this.translate.use(this.translate.currentLang); } // Set the used language to currentLang from the web settings of user(app.ts)

  ngOnInit(): void {
    /* console.log("current LANGUAGE: ", this.translate.currentLang); */

    if (this.translate.currentLang === 'el') {
      this.NavLink1 = true;
      this.NavLink2 = false;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'en') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = true;
      this.NavLink4 = false;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'fr') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = true;
      this.NavLink6 = false;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'ro') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = true;
      this.NavLink8 = false;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = true;
      this.NavLink10 = false;
    }

    this.ActivatedRoute.queryParams.subscribe(params => {
      /* console.log(params); */
      const authCodeFromUrl = params['auth_code'];
      if (authCodeFromUrl && authCodeFromUrl.length === 10) {
        this.user.auth_code = authCodeFromUrl;
        /* console.log("hey!", this.user.auth_code); */
      }
    });

    /* this.ActivatedRoute.queryParams.subscribe(params => {
      const authCodeFromUrl = params['auth_code'];
      if (authCodeFromUrl && authCodeFromUrl.length === 10) {
        this.user.auth_code = authCodeFromUrl;
        this.isAuthCodeLocked = true;
        // Εκτυπώστε την κατάσταση της φόρμας
        console.log('Form status after auth_code set:', this.userForm?.form.valid);
        this.checkButtonState();
      }
    }); */

    /* this.ActivatedRoute.queryParams.subscribe(params => {
      console.log(params);
      const authCodeFromUrl = params['auth_code'];
      if (authCodeFromUrl && authCodeFromUrl.length === 10) {
        // Αποθηκεύστε το auth_code στο localStorage πριν η σελίδα επαναφορτωθεί
        localStorage.setItem('auth_code', authCodeFromUrl);
      }
    });

    // Ελέγξτε αν υπάρχει αποθηκευμένο auth_code μετά την επαναφόρτωση
    const savedAuthCode = localStorage.getItem('auth_code');
    if (savedAuthCode) {
      this.user.auth_code = savedAuthCode;
      console.log("hey!", this.user.auth_code);
      this.isAuthCodeLocked = true;
      // Καθαρίστε το localStorage αφού το auth_code έχει χρησιμοποιηθεί
      localStorage.removeItem('auth_code');
    } */
  }

  useLanguage(language: string) {
    this.translate.use(language);

    if (language === 'el') {
      this.NavLink1 = true;
      this.NavLink2 = false;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'el'
    }
    else if (language === 'en') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = true;
      this.NavLink4 = false;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'en'
    }
    else if (language === 'fr') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = true;
      this.NavLink6 = false;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'fr'
    }
    else if (language === 'ro') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = true;
      this.NavLink8 = false;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'ro'
    }
    else {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = true;
      this.NavLink10 = false;
      this.translate.currentLang = 'no'
    }
    /* console.log("LANGUAGE: ", language);
    console.log("current LANGUAGE: ", this.translate.currentLang); */
  }

  /* onForgotSubmit() {
    this.router.navigate(['/forgot-pass']);
  }

  openModal() {
    this.modalRef = this.modalService.show(AboutApp2Component);
  } */

  openTermsModal(event: MouseEvent) {
    event.preventDefault(); // Αυτό θα αποτρέψει το checkbox να τσεκάρεται
    this.modalRef = this.modalService.show(OpenModalTermsOfUseComponent);
  }

  // Προσθέστε το event parameter στην onTermsChecked function
  onTermsChecked(event: Event): void {
    this.isTermsChecked = (event.target as HTMLInputElement).checked;
  }

  onLoginSubmit(userForm: NgForm) {
    // αποθήκευση τιμών στο localStorage
    localStorage.setItem('selectedLanguage', JSON.stringify(this.translate.currentLang));

    // Request body login
    const login_res = {
      /* username: this.user.username,
      password: this.user.password */
      code: this.user.auth_code
    };

    if (!userForm.form.valid) {
      this.translate.get('Please enter the invitation code to log in!').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }

    /* if (!userForm.form.valid || !this.ValidateEmailService.validateEmail(this.user.username)) {
      this.translate.get('Please enter a valid email to sign up').subscribe(res => {
        console.log(res);
        this.toastr.error(res);
      });
    } */
    else {
      /* console.log("USER:", this.user); */
      this.authService.authenticateUser(login_res)
        .pipe(first())
        .subscribe({
          next: data => {
            /* console.log("USER:", this.user);
            console.log(data); */
            this.translate.get('You are now logged in!').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });
            this.authService.storeUserData(data.token, data.user);

            // Get User Profile
            this.userService.getProfile()
              .pipe()
              .subscribe({
                next: res => {
                  /* console.log("User profile: ", res); */
                  this.dataLayerService.addUser(res);
                  this.router.navigate(['/about-app']);
                },
                error: error => {
                  if (error.status === 401) {
                    this.translate.get('Unauthorized').subscribe(res => {
                      /* console.log(res); */
                      this.toastr.error(res);
                    });
                  }
                }
              });
          },
          error: error => {
            if (error.status == 401) {
              this.translate.get('Invalid email address or password').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
            else {
              this.translate.get('Something went wrong. Please try again.').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
          }
        });
    }
  }

  /* validateAuthCode(event: any) {
    const input = event.target.value;
    const numbers = /^[0-9]+$/;

    if (!input.match(numbers) || input.length !== 10) {
      event.target.setCustomValidity('Invalid input. Please enter a 10-digit number.');
    } else {
      event.target.setCustomValidity('');
    }
  } */
}