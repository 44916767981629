<nav class="navbar navbar-expand-lg navbar-light bg-light"> <!-- fixed-top -->
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['/home']" (click)="collapseNav()">
            <img class="img-logo" alt="Evivios logo" src="assets/images/evivios_med.png">
        </a>
        <button #navbarToggler class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbarCollapsing()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div [class.collapse]="navbarCollapsed" class="collapse navbar-collapse all-item-mdiv" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-auto all-item-mul">
                <!-- <li class="nav-item navbar-center">
                    <a class="nav-link menu" [routerLink]="['/works']" [routerLinkActive]="['active']" 
                    [routerLinkActiveOptions]="{exact:true}" style="margin-right: 10px;" (click)="collapseNav()">{{'TASKS' | translate}}</a>
                </li> -->
            </ul>
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-auto">
                <!-- <li class="nav-item">
                    <button class="image-button" [routerLink]="['/']" data-toggle="tooltip" data-placement="bottom" title="{{'Log out' | translate}}" (click)="logoutUser()">
                        <img class="log-out-image" src="assets/images/log-out.png">
                    </button>
                </li> -->
            </ul>
        </div>
    </div>
</nav>
