<div class="container">

  <div class="row">
    <div class="col">
      <p class="title2">{{questionnaire_name}}</p>
    </div>
    <div class="col" style="text-align: end;padding-top: 20px;">
      <div *ngIf="timeLeft">
        <div [class.timer]="true" [class.timer-red]="timeLeft <= '00:10'">{{ timeLeft }}</div>
      </div>
    </div>
  </div>

  <div class="steps-info">
    <div class="row">
      <div class="col">
        <p class="steps">{{ (questionIndex+1) + ' / ' + questionnaireOneData.items.length}}</p>
      </div>
      <div class="col" style="padding-top: 20px;">
        <div class="progress">
          <div class="progress-bar" 
               [style.width.%]="progressPercentage"
               role="progressbar"
               [attr.aria-valuenow]="progressPercentage"
               aria-valuemin="0"
               aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="answersForm" (ngSubmit)="onAnswerSubmitted()">
    <div *ngIf="questionIndex <= questionnaireOneData.items.length">
      <p class="que-title">{{questionnaireOneData.items[questionIndex]?.question}}</p>
      
      <div class="options-container" [ngClass]="{'horizontal-options': questionnaireOneData.items[questionIndex]?.answers.length > 6}">
        <div *ngFor="let answer of questionnaireOneData.items[questionIndex]?.answers" class="options-format">
          <input class="form-check-input" formControlName="selectedOption" type="radio" name="selectedOption" 
            [value]="answer" id="{{ answer }}" required (change)="nextQuestion()" style="display: none;"> 
          <label class="form-check-label" for="{{ answer }}">{{ questionnaireOneData.answers[answer-1]['answer'] }}</label>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col" style="text-align: center;">
          <button (click)="nextQuestion()" class="btn-black" [disabled]="!optionSelected">{{ questionIndex == questionnaireOneData.items.length - 1 ? ('Submission' | translate) : ('Continue' | translate) }}</button>
        </div>
      </div> -->
    </div>
  </form>

</div>