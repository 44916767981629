<div class="container">

    <div class="top"style="text-align: center;justify-content: center;">
      <a [routerLink]="['/login']">
        <img alt="logo" src="assets/images/evivios_med.jpg" class="login-logo">
      </a>
    </div>

    <div class="mb-3" style="display: flex;justify-content: center;">
        <p class="paragraph">{{'Please enter your email to receive the verification code.' | translate}}
    </div>
            
    <form #userForm="ngForm" (NgSubmit)="userForm.valid && onSubmit(userForm)">
      
      <div class="row" style="display: flex;justify-content: center;margin-top: 10px;">
        <div class="col" style="display: flex;justify-content: end;">
            <a *ngIf="NavLink1" class="nav-link el-active" value="el" (click)="useLanguage('el')" style="border: none; cursor: pointer; background: #fff;margin-top: -1px"> 
              <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}</a>
            <a *ngIf="NavLink2" class="nav-link el" value="el" (click)="useLanguage('el')" style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
              <img class="img-country" width="20px" src="assets/images/greece.png" alt="Greek Flag" class="flag-icon"> {{'EL' | translate}}</a>
        </div>
        <div class="col">
          <a *ngIf="NavLink3" class="nav-link en-active" value="en" (click)="useLanguage('en')"  style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
            <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
          </a>
          <a *ngIf="NavLink4" class="nav-link en" value="en" (click)="useLanguage('en')"  style="border: none; cursor: pointer; background: #fff;margin-top: -1px">
            <img class="img-country" width="20px" src="assets/images/united-kingdom.png" alt="British Flag" class="flag-icon"> {{'EN' | translate}}
          </a>
        </div>
      </div>
  
        <div class="row" style="display: flex;justify-content: center;">
          <div class="col" style="display: flex;justify-content: center;">
            <div class="mb-3 email-field">
              <label for="exampleInputEmail1" class="form-label">{{'Email' | translate}}</label>
              <input type="text" class="form-control email-input" id="exampleInputEmail1" aria-describedby="emailHelp" name="username" [(ngModel)]="user.username" #username="ngModel" email required style="background-image: url('assets/images/email-icon.png');">
              <small class="smallclass" *ngIf="username.touched && !username.valid">{{'Enter your email address!' | translate}}</small>
              <br>
              <small class="smallclass" *ngIf="username.touched && !username.valid">{{'Must be a valid email address.' | translate}}</small>
            </div>
          </div>
        </div>
    
        <div class="row" style="display: flex;justify-content: center;">
          <div class="col" style="display: flex;justify-content: center;">
            <div class="mb-3">
              <button type="submit" class="btn-black" [disabled]="!userForm.form.valid" (click)="onSubmit(userForm)">{{'NEXT' | translate}}</button>
            </div>
          </div>
        </div>
    </form>
    
  </div>
