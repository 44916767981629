<app-navbar></app-navbar>
<div class="container">
   <!--  <p>parent-questionnaire-one works!</p> -->
    <app-question></app-question>
    
    <!-- <p>seperate</p> -->
    
    <!-- <div *ngIf="questionnaireOneData">
        <app-question [questionnaireOneData]="questionnaireOneData[currentQuestionIndex]"></app-question>
        <button (click)="nextQuestion()" [disabled]="currentQuestionIndex === questionnaireOneData.length - 1">Next</button>
    </div> -->
    
</div>

<!-- BEFORE CHANGES WORKS
<div class="container">
    <p>parent-questionnaire-one works!</p>
    <app-question [questionnaireOneData]="questionnaireOneData"></app-question>
    
    <p>seperate</p>
    
    <div *ngIf="questionnaireOneData">
        <app-question [questionnaireOneData]="questionnaireOneData[currentQuestionIndex]"></app-question>
        <button (click)="nextQuestion()" [disabled]="currentQuestionIndex === questionnaireOneData.length - 1">Next</button>
    </div>
    
</div> -->