import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: any;
  user: any;

  constructor(private http: HttpClient) { }

  registerUser(user: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json')
    return this.http.post<AuthService>(environment.apiUrl + '/registration', user, { headers: headers }).pipe(
      map(resp => {
        return resp;
      }));
  }

  /*
  * Retrieve JWT token
  */
  authenticateUser(credentials: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json')
    return this.http.post<AuthService>(environment.apiUrl + '/web/oauth/token', credentials, { headers: headers }).pipe(
      map(user => {
        return user;
      }));
  }

  forgotPassword(user: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json')
    return this.http.post<AuthService>(environment.apiUrl + '/reset_password/', user, { headers: headers }).pipe(
      map(resp => {
        return resp;
      }));
  }

  forgotPassword2(user: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json')
    return this.http.post<AuthService>(environment.apiUrl + '/set_password/', user, { headers: headers }).pipe(
      map(resp => {
        return resp;
      }));
  }

  /*
  * Load token from local storage
  */
  loadToken() {
    const token = localStorage.getItem('id_token');
    this.token = token;
    return token;
  }

  /*
  * Decode token and retrieve user id
  */
  getUserIdFromToken() {
    this.loadToken();
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.token);
    return decodedToken.id;
  }

  /*
  * Decode token and retrieve user role or any other information
  */
  /* getUserRoleFromToken() {
    this.loadToken();
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.token);
    return decodedToken.role;
  } */

  /*
  * Check if user's logged in or not. Returns true if: 
  *   1. There is not a token stored in local storage
  *   2. Token stored in local storage has been expired
  */
  notLoggedIn() {
    if (localStorage['id_token'] === undefined) {
      return true
    } else {
      const helper = new JwtHelperService();
      return helper.isTokenExpired(localStorage['id_token']);
    }
  }

  /*
  * Store token in local storage
  */
  storeUserData(token: any, user: any) {
    localStorage.setItem('id_token', token);
    this.token = token;
    this.user = user;
  }

  /*
  * Clear local storage when user's logs out
  */
  logout() {
    this.token = null;
    localStorage.clear();
  }
}