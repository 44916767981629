import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private user_info = new BehaviorSubject<any[]>([]);

  public currentUser = this.user_info.asObservable();

  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {
    this.restoreStateOnRefresh();
  }

  /*
  * Set authenticated user
  */
  public addUser(layer: any) {
    this.user_info.next(
      [...this.user_info.getValue(), layer]
    );
  }

  /*
  * Return user
  */
  public getUser() {
    return this.user_info;
  }

  /*
  * Get user's firstname
  */
  public getUserFirstName() {
    return this.user_info.value[0]['firstname'];
  }

  /*
  * Get user's lastname
  */
  public getUserLastName() {
    return this.user_info.value[0]['lastname'];
  }

  /*
  * Remove user
  */
  public removeUser() {
    this.user_info.next([]);
  }

  /*
  * Check if user is logged in and restore user subject
  */
  public restoreStateOnRefresh() {
    // Keep state on page refresh
    const isNotLoggedIn = this.authService.notLoggedIn();
    if (!isNotLoggedIn) {
      this.userService.getProfile()
        .pipe()
        .subscribe({
          next: res => {
            this.addUser(res);
          },
          error: error => {
            if (error.status == 401) {
            }
          }
        });
    }
  }
}