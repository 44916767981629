<app-navbar></app-navbar>
<div class="container">

    <form [formGroup]="StepOneForm" (ngSubmit)="onAnswerSubmitted()">
        <div class="row" style="margin-top: 50px;">
          <div class="col"> 
            <h2 class="title">{{ 'Questionnaire' | translate }}</h2>
          </div>
          <div class="col" style="text-align: end;">
            <div *ngIf="timeLeft">
              <div class="timer">{{ timeLeft }}</div>
            </div>
          </div>
        </div>
        <hr>
        <div class="steps-info">
          <div class="row">
            <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <p class="step-num">{{question_length}}/{{all_questions_length}}</p>
            </div>
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10" style="padding-top: 20px;">
              <div class="progress">
                <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width:5%;position: relative;"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col" style="text-align: center;">
            <!-- Greek language -->
            <p class="que-title" *ngIf="translate.currentLang === 'el'">{{ question1_el }}</p> <!-- {{ question1_el }} -->
            <!-- English language -->
            <p class="que-title" *ngIf="translate.currentLang === 'en'">{{ question1_en }}</p> <!-- {{ question1_en }} -->
          </div>
        </div>
        <!-- Greek language -->
        <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-top: 20px;" *ngIf="translate.currentLang === 'el'">
          <div *ngFor="let option of options_el" class="options-format">
            <input formControlName="selectedOption" class="form-check-input" type="radio" id="{{ option }}" name="selectedOption" value="{{ option }}" required (click)="optionSelected = true" />
            <label class="form-check-label" for="{{ option }}">{{ option }}</label>
          </div>
        </div>
        <!-- English language -->
        <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-top: 20px;" *ngIf="translate.currentLang === 'en'">
          <div *ngFor="let option of options_en" class="options-format">
            <input formControlName="selectedOption" class="form-check-input" type="radio" id="{{ option }}" name="selectedOption" value="{{ option }}" required (click)="optionSelected = true" />
            <label class="form-check-label" for="{{ option }}">{{ option }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col" style="text-align: center;">
            <button type="submit" class="btn-black" [disabled]="!optionSelected">{{ 'Continue' | translate }}</button>
          </div>
        </div>
      </form>


    <!-- <form #userForm="ngForm" (NgSubmit)="userForm.valid && onSubmit(userForm)">
    <div class=row>
        <div class=col>
            <div class="questionnaire">
                <h2 class="title">{{'Questionnaire' | translate}}</h2>
            </div>
        </div>
        <div class=col>
            <div class="countdown-container">
                <div class="countdown-ellipse">
                    <img class="image" alt="logo" src="assets/images/Ellipse 1.png" width="112" height="92">
                </div>
                <div class="countdown">
                    <div *ngIf="timeLeft">
                      <div class="countdown__item">
                        <div class="countdown__value">{{ timeLeft }}</div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="line">
        <hr class="long">
    </div>
    <div class=row>
        <div class=col-md-4>
            <div class="rectangle">
                <img class="image" alt="logo" src="assets/images/Rectangle 26.png" width="68" height="50">
                <p class="paragraph">1/20</p>
            </div>
        </div>
        <div class=col-md-4>
            <div class="progress">
                <div class="progress-bar" role="progressbar" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100" style="width:5%;position: relative;">
                  <div class="elipse2" style="position: absolute; right: -12px; background-image: url('assets/images/Ellipse2-bar.png'); width: 25px; height: 25px; display: flex; justify-content: center; align-items: center;">
                    <div class="elipse" style="background-image: url('assets/images/Ellipse-bar.png'); width: 8px; height: 8px;"></div>
                  </div>
                </div>
            </div>           
        </div>
    </div>
    <div class=question>
        <p class="paragraph2">Question 1</p>
    </div>
    <div class="radios">
        <div class="ng" *ngFor="let option of options">
            <div class="form-check">
              <input [(ngModel)]="selectedOption" class="form-check-input" type="radio" id="{{ option }}" name="options" value="{{ option }}" required>
              <label class="form-check-label" for="{{ option }}">{{ option }}</label>
            </div>
        </div>
    </div>
    <div class="continue">
        <button type="submit" class="btn-black" [disabled]="!userForm.form.valid" (click)="onSubmit(userForm)">{{'Continue' | translate}}</button>
    </div>
    </form> -->
</div>
