import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { DataLayerService } from '../services/data-layer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public navbarCollapsed = true;

  public NavLink1: boolean = false;
  public NavLink2: boolean = false;
  public NavLink3: boolean = false;
  public NavLink4: boolean = false;
  public NavLink5: boolean = false;
  public NavLink6: boolean = false;
  public NavLink7: boolean = false;
  public NavLink8: boolean = false;
  public NavLink9: boolean = false;
  public NavLink10: boolean = false;

  user_id: string = "";

  public questionnaire_items_all: any = [];

  @ViewChild('navbarToggler') navbarToggler!: ElementRef; //put "!" to tell that the property will not be null or undefined at runtime

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    public authService: AuthService,
    private userService: UserService,
    public dataLayerService: DataLayerService
  ) { this.translate.use(this.translate.currentLang); } // Set the current language to the current language from login

  ngOnInit(): void {
    if (this.translate.currentLang === 'el') {
      this.NavLink1 = true;
      this.NavLink2 = false;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'en') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = true;
      this.NavLink4 = false;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'fr') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = true;
      this.NavLink6 = false;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'ro') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = true;
      this.NavLink8 = false;
      this.NavLink9 = false;
      this.NavLink10 = true;
    }
    else if (this.translate.currentLang === 'no') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = true;
      this.NavLink10 = false;
    }

    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.user_id = user[0]._id;
        const USER_ID = this.user_id;
        /* console.log("USER_ID:", USER_ID);
        console.log("USER:", user); */
        // αποθήκευση τιμών στο localStorage
        localStorage.setItem('USER_ID', JSON.stringify(USER_ID));
        this.userService.getProfile(this.user_id).pipe().subscribe({
          next: data => {
            /* console.log(data); */
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
      }
    });

    /* console.log("LANGUAGE: ", this.translate.currentLang); */
  }

  logoutUser() {
    this.authService.logout();
    this.dataLayerService.removeUser();
    this.translate.get('You are logged out!').subscribe(res => {
      /* console.log(res); */
      this.toastr.error(res);
    });
  }

  toggleNavbarCollapsing() {
    this.navbarCollapsed = !this.navbarCollapsed;
    /* console.log("navbarCollapsed boolean timhhh:", this.navbarCollapsed); */
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);

    if (language === 'el') {
      this.NavLink1 = true;
      this.NavLink2 = false;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'el'
    }
    else if (language === 'en') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = true;
      this.NavLink4 = false;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'en'
    }
    else if (language === 'fr') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = true;
      this.NavLink6 = false;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'fr'
    }
    else if (language === 'ro') {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = true;
      this.NavLink8 = false;
      this.NavLink9 = false;
      this.NavLink10 = true;
      this.translate.currentLang = 'ro'
    }
    else {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = false;
      this.NavLink4 = true;
      this.NavLink5 = false;
      this.NavLink6 = true;
      this.NavLink7 = false;
      this.NavLink8 = true;
      this.NavLink9 = true;
      this.NavLink10 = false;
      this.translate.currentLang = 'no'
    }
    /* console.log("LANGUAGE: ", language);
    console.log("current LANGUAGE: ", this.translate.currentLang); */
  }
}