import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  /*
  * Get specific user's profile information
  */
  getProfile(profile_id: string = '') {
    const token = this.authService.loadToken();

    let user_id: string;

    if (!profile_id) {
      user_id = this.authService.getUserIdFromToken();
    } else {
      user_id = profile_id
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get<any>(environment.apiUrl + '/users/' + user_id, { headers: headers }).pipe();
  }

  /*
  * Get all user's profile information
  */
  getUsers() {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get<any>(environment.apiUrl + '/users', { headers: headers }).pipe();
  }

  /*
  * Retrieve questionnaires
  */
 /* Get questionnaires without language parameter */
  getQuestionnaires() {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get<any>(environment.apiUrl + '/questionnaires', { headers: headers }).pipe();
  }
  
  /* Get questionnaires with language parameter */
  getQuestionnairesNew(language: string = '') {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get<any>(environment.apiUrl + '/questionnaires/' + '?where={"language":"' + language + '"}', { headers: headers }).pipe();
  }

  /* Get specific questionnaire */
  getSpecificQuestionnaire(_id: string = '') {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get<any>(environment.apiUrl + '/questionnaires/' + _id, { headers: headers }).pipe();
  }

  /*
  * Retrieve questionnaire answers
  */
  getQuestionnaireAnswers() {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get<any>(environment.apiUrl + '/answers', { headers: headers }).pipe();
  }

  /*
  * Save questionnaire answers
  */
  saveQuestionnaireAnswers(saveAnswersBody: any) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.post<any>(environment.apiUrl + '/answers', saveAnswersBody, { headers: headers }).pipe();
  }

  getPrivacyPolicy(language: string = ''): Observable<HttpResponse<Blob>> {
    /* const token = this.authService.loadToken(); */
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/pdf'/* ,
      'Authorization': 'Bearer ' + token */
    });

    let url = 'https://mental.eviviosmed.com/api/privacy_policy';
    let params = new HttpParams().set('language', language);

    return this.http.get(url, {
      headers: headers,
      params: params,
      responseType: 'blob',
      observe: 'response'
    });
  }

  getAboutApp(language: string = '') {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get<any>(environment.apiUrl + '/app_info/' + '?where={"language":"' + language + '"}', { headers: headers }).pipe();
  }

  getCongratsText(language: string = '') {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get<any>(environment.apiUrl + '/congrats/' + '?where={"language":"' + language + '"}', { headers: headers }).pipe();
  }
}