import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { DataLayerService } from '../services/data-layer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar2',
  templateUrl: './navbar2.component.html',
  styleUrls: ['./navbar2.component.css']
})
export class Navbar2Component implements OnInit {
  public navbarCollapsed = true;

  user_id: string = "";

  public questionnaire_items_all: any = [];

  @ViewChild('navbarToggler') navbarToggler!: ElementRef; //put "!" to tell that the property will not be null or undefined at runtime

  constructor (private toastr: ToastrService,
  public translate: TranslateService,
  public authService: AuthService,
  private userService: UserService,
  public dataLayerService: DataLayerService
) { this.translate.use(this.translate.currentLang); } // Set the current language to the current language from home

  ngOnInit(): void {
    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.user_id = user[0]._id;
        const USER_ID = this.user_id;
        /* console.log("USER_ID:", USER_ID);
        console.log("USER:", user); */
        // αποθήκευση τιμών στο localStorage
        localStorage.setItem('USER_ID', JSON.stringify(USER_ID));
        this.userService.getProfile(this.user_id).pipe().subscribe({
          next: data => {
            /* console.log(data); */
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
      }
    });

    /* console.log("LANGUAGE: ", this.translate.currentLang); */
  }

  logoutUser() {
    this.authService.logout();
    this.dataLayerService.removeUser();
    this.translate.get('You are logged out!').subscribe(res => {
      /* console.log(res); */
      this.toastr.error(res);
    });
  }

  toggleNavbarCollapsing() {
    this.navbarCollapsed = !this.navbarCollapsed;
    /* console.log("navbarCollapsed boolean timhhh:", this.navbarCollapsed); */
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }

}
