<!-- new correct dynamic try  -->
<app-navbar></app-navbar>
<div class="container">
  
  <div *ngIf="emptyQuestionnaires" class="row" style="display: flex;justify-content: left;">
    <div class="col" style="display: flex;justify-content: left;">
        <div class="mb-3">
            <p class="paragraph">{{'YOU HAVE COMPLETED THE QUESTIONNAIRES SENT TO YOU BY YOUR DOCTOR' | translate}}</p>
        </div>
    </div>
  </div>

  <ng-container *ngIf="loadedQuestionnaires">
    <div class="all_questionnaires_dynamic" *ngFor="let item of questionnaire_items_all; let i = index">
      <ng-container *ngIf="item.questionnaire_id === '1'">
        <div class="que-card card">
          <!-- Κώδικας για τις ενεργές ερωτησεις -->
          <div class="row">
            <div class="col">
              <h5 class="title-me">{{ item.name }}</h5>
            </div>
            <div class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
              <button class="image-button-me" (click)="onclick(i)">
                <img class="image3-me" alt="logo" src="assets/images/start.png" width="52" height="52">
              </button>
            </div>
          </div>
          

          <!-- <div class="row"> 
            <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" style="margin-top: 17px;margin-left: 0px;">
              <hr>
            </div>
            <div class="col-6 col-sm-6 col-md-5 col-lg-5 col-xl-5" style="text-align: end;margin-top: 18px;">
              <p class="paragraph3-me" style="cursor: pointer;" [routerLink]="['/questions-info/' + questionnaire_items_all[i]._id]">{{'Get started' | translate}}</p>
            </div>
            <div class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
              <button class="image-button-me" (click)="onclick(i)">
                <img class="image3-me" alt="logo" src="assets/images/start.png" width="62" height="62">
              </button>
            </div>
          </div> -->

          <!-- <div class="row">
            <div class="col" style="margin-bottom: 20px;">
              <img class="image1-me" alt="logo" src="assets/images/questions.png" width="20" height="20">
              <p class="paragraph1-me d-inline">{{question_id[i]}} {{'questions' | translate}}</p>
              <img class="image2-me" alt="logo" src="assets/images/clock.png" width="20" height="20">
              <p class="paragraph2-me d-inline">{{question_id[i] * 2}} {{'minutes' |translate}}</p>
            </div>
          </div> -->
      
        </div>
      </ng-container>
    </div>
  
    <!-- <div class="all_questionnaires_dynamic" *ngFor="let item of questionnaire_items_all; let i = index">
      <ng-container *ngIf="item.is_completed && item.is_visible">
        <div class="que-card-complete card">
          
          <h5 class="title-me-completed">{{ item.name }}</h5>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" style="margin-top: 17px;margin-left: 0px;">
              <hr class="hr-complete">
            </div>
            <div class="col-6 col-sm-6 col-md-5 col-lg-5 col-xl-5" style="text-align: end;margin-top: 18px;">
              <p class="paragraph3-me-complete">{{'Completed' | translate}}</p>
            </div>
            <div class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
              <button class="image-button-me" style="cursor: default;">
                <img class="image-checked" alt="logo" src="assets/images/check.png">
              </button>
            </div>
          </div>
  
          <div class="row">
            <div class="col" style="margin-bottom: 20px;">
              <img class="image1-me" alt="logo" src="assets/images/questions-complete.png" width="20" height="20">
              <p class="paragraph1-me-complete d-inline">{{question_id[i]}} {{'questions' | translate}}</p>
              <img class="image2-me" alt="logo" src="assets/images/clock-complete.png" width="20" height="20">
              <p class="paragraph2-me-complete d-inline">{{question_id[i] * 2}} {{'minutes' |translate}}</p>
            </div>
          </div>

        </div>
      </ng-container>
    </div> -->
  </ng-container>

</div>